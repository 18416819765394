export default {
  container: {},
  header: {},
  list: {},
  listItem: {
    width: 150,
    height: 50,
    borderRadius: 1,
    background: '#dd4040',
    boxShadow: '0px 5px 30px rgba(0, 0, 0, 0.07)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 2,
    cursor: 'pointer',
    '& *': {
      color: '#fff',
    },
  },
  categoryName: {
    color: '#000',
    '& span': {
      fontSize: 10,
    },
    '&:hover': {
      color: theme => theme.palette.primary.main,
    },
  },
};
