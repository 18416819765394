import React from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

import { Paper, Typography, Box } from '@mui/material';
// import { Link } from 'react-router-dom';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import {
  addProduct,
  deleteProduct,
  minusProduct,
} from '../../../redux/cart/actions';
import SelectQuantity from '../../SelectQuantity';
import styles from './syles';
import { getProductMedia } from '../../../services/exploreService';

function ProductCartGrid({ product, ...props }) {
  const { productsCart } = useSelector(state => state.cart);
  const productInCart = productsCart.find(pd => pd.id === product.id);
  const classes = styles;
  const dispatch = useDispatch();
  return (
    <Paper elevation={0} sx={classes.container}>
      {/* <div style={classes.trash}>
        <DeleteOutlinedIcon onClick={() => dispatch(deleteProduct(product))} />
      </div> */}
      <Box
        sx={{
          ...classes.image,
          backgroundImage: product?.medias?.[0]?.uri
            ? 'url("' + getProductMedia(product.medias[0].uri) + '")'
            : 'none',
        }}
      />

      <Typography sx={classes.productName}>{product.name}</Typography>
      <Typography sx={classes.productList}>
        {product.food &&
          product.food.addtionals &&
          product.food.addtionals.map((item, i) => <>{item.name}</>)}
      </Typography>
      <Typography sx={classes.productList}>
        {product.food &&
          product.food.removeOptionals &&
          product.food.removeOptionals.length > 0 &&
          'Sem '}
        {product.food &&
          product.food.removeOptionals &&
          product.food.removeOptionals.map((item, i) => <>{item.name}</>)}
      </Typography>
      <Typography sx={classes.productPrice}>
        {Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(Number(+product.price * (productInCart?.qtd || 1)))}
      </Typography>
      <SelectQuantity
        value={product.qtd}
        actionAdd={() => dispatch(addProduct(product))}
        actionMinus={() => dispatch(minusProduct(product))}
      />
    </Paper>
  );
}

export default ProductCartGrid;
