export default {
  headerSearch: {
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddding: 2,
    color: theme => theme.palette.grey[800],
    fontSize: 10,
    marginBottom: 5,
  },
  details: {
    maxWidth: 350,
    marginLeft: 2,
  },
  slidercontainer: {
    minHeight: 500,
    maxHeight: 500,
    width: '100%',
    height: 'auto',
    // backgroundColor: theme => theme.palette.grey[500],
  },
  sliderProduct: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    // backgroundColor: theme => theme.palette.grey[500],
  },
  title: {
    marginTop: 4,
    marginBottom: 2,
  },
  shortDescription: {
    marginBottom: 4,
    color: theme => theme.palette.grey[700],
  },
  price: {
    marginTop: 4,
    marginBottom: 4,
    color: theme => theme.palette.grey[700],
  },
  buttonCart: {
    marginTop: 4,
    marginBottom: 4,
  },
  quantity: {
    wisth: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },
  sectionDescrition: {
    marginTop: 4,
    marginBottom: 4,
  },
  descriptionTitle: {
    marginBottom: 2,
    color: theme => theme.palette.primary.dark,
  },
  descriptionText: {
    color: theme => theme.palette.grey[600],
  },
};
