import Login from './login';
import Register from './register';
import Home from './home';
import Search from './search';
import Product from './product';
import Cart from './cart';
import Account from './account';
import Category from './Category';
import Checkout from './checkout';
import NotFoundPage from './notfoundPage';
import Order from './order';
import Catalog from './Catalog';
import OrderList from './orderList';
import AddressList from './account/AddressList';
import AddressEdit from './account/Address';
import AccountEdit from './account/Edit';

export default {
  Login,
  Register,
  Home,
  Search,
  Product,
  Cart,
  Account,
  Order,
  Category,
  Checkout,
  NotFoundPage,
  Catalog,
  OrderList,
  AddressList,
  AddressEdit,
  AccountEdit,
};
