import React, { useEffect, useState } from 'react';
import { Grid, Container, CircularProgress, Typography } from '@mui/material';

import { useLocation } from 'react-router-dom';
import qs from 'qs';
import Product from '../../components/ProductViews/ProductListGrid';
import Input from './Input';

// api services
import { getCategories, postSearch } from '../../services/exploreService';

import useStyles from './styles';

export function Search() {
  const classes = useStyles;
  const params = useLocation();

  const [categoriesList, setCategoriesList] = useState([]);

  const [search, setSearch] = useState(null);
  const [category, setCategory] = useState(null);

  const [products, setProducts] = useState([]);
  const [page /* , setPage */] = useState(0);

  const [loading, setLoading] = useState(true);

  // search categories list
  useEffect(() => {
    async function load() {
      const categories = await getCategories();
      setCategoriesList(categories);
    }
    load();
  }, []);
  // search init
  useEffect(() => {
    const filter = qs.parse(params.search, { ignoreQueryPrefix: true });

    console.log(filter);

    if (filter.category) setCategory(filter.category);

    if (filter.search) setSearch(filter.search);
  }, [params]);

  // load products
  useEffect(() => {
    async function load() {
      if (!search && !category) {
        setLoading(false);
        return;
      }
      const response = await postSearch({ page, search, category });
      setProducts(response);
      console.log('pesquisa');
      console.log({ page, search, category });
      console.log(response);
      setLoading(false);
    }
    load();
    console.log(params);
  }, [params, search, page, category]);
  return (
    <div>
      <div style={classes.headerSearch}>
        <Input
          search={search}
          setSearch={search => {
            setLoading(true);
            setSearch(search);
          }}
          category={category}
          setCategory={category => {
            setLoading(true);
            setSearch(category);
          }}
          categoriesList={categoriesList}
        />
      </div>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          {loading && <CircularProgress />}

          {!loading &&
            products.length > 0 &&
            products.map((item, index) => (
              <Grid item sm={4} xs={6} md={3} lg={2} key={index}>
                <Product product={item} />
              </Grid>
            ))}
          {!loading && products.length === 0 && (search || category) && (
            <Typography variant="h5" component="h1">
              Nenhum Produto encontrado
            </Typography>
          )}
          {!loading && !search && !category && products.length === 0 && (
            <Typography variant="h5" component="h1">
              Faça uma pesquisa
            </Typography>
          )}
        </Grid>
      </Container>
    </div>
  );
}

export default Search;
