import React, { useState, useRef, useEffect } from 'react';
// custom components
import Button from '../../../components/Button';
import {
  Grid,
  MenuItem,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
} from '@mui/material';

/** api */
import {
  postAddress,
  putAddress /* getNeighborhood*/,
} from '../../../services/accountService';
import { IMaskInput } from 'react-imask';
import PropTypes from 'prop-types';

/** redux  */
import { useDispatch } from 'react-redux';
import { setAddress } from '../../../redux/cart/actions';
// formik
import { Formik, Form } from 'formik';
// validation
import AddressSchema from './validation';
// styles
import useStyles from './styles';
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(00) 00000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
function AddressForm({ address, ceps, setIsEdit }) {
  const classes = useStyles;
  const dispatch = useDispatch();
  const [loadingSaveAddress, setLoadingSaveAddress] = useState(false);
  const [neighborhood, setneighborhood] = useState(address?.neighborhood || '');

  const onSubmitAddress = async data => {
    console.log(data);
    try {
      if (address && address.id) {
        // update address
        await putAddress(address.id, {
          ...data,
          zip_code: neighborhood.cep,
          filial_deliverie_ceps_id: neighborhood.id,
        })
          .then(res => {
            dispatch(setAddress(res.activeAddress));
            setIsEdit(false);
          })
          .catch(error => {
            setLoadingSaveAddress(false);
          });
      } else {
        // create address
        await postAddress({
          ...data,
          zip_code: neighborhood.cep,
          filial_deliverie_ceps_id: neighborhood.id,
        })
          .then(res => {
            dispatch(setAddress(res.activeAddress));
            setIsEdit(false);
          })
          .catch(error => {
            setLoadingSaveAddress(false);
          });
      }
      setLoadingSaveAddress(false);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Box sx={classes.form}>
      <Formik
        initialValues={
          address || {
            first_name: '',
            last_name: '',
            phone_number: '',
            street: '',
            number: '',
            neighborhood: '',
          }
        }
        validationSchema={AddressSchema}
        onSubmit={values => {
          console.log(values);
          setLoadingSaveAddress(true);
          onSubmitAddress(values);
        }}
      >
        {({ errors, values, handleChange }) => (
          <Form style={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="first_name"
                  type="text"
                  placeholder="Nome"
                  required
                  onChange={handleChange}
                  value={values.first_name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="last_name"
                  type="text"
                  placeholder="Sobrenome"
                  required
                  onChange={handleChange}
                  value={values.last_name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                {/* <TextField
                  name="phone_number"
                  type="text"
                  placeholder="Telefone"
                  required
                  onChange={handleChange}
                  value={values.phone_number}
                  fullWidth
                /> */}
                <FormControl variant="standard">
                  <InputLabel htmlFor="phone_number-mask-input">Telefone</InputLabel>
                  <OutlinedInput
                    name="phone_number"
                    id="phone_number-mask-input"
                    inputComponent={TextMaskCustom}
                    required
                    onChange={handleChange}
                    value={values.phone_number}
                    sx={classes.inputOutlinedInput}
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="street"
                  type="text"
                  placeholder="Rua"
                  required
                  onChange={handleChange}
                  value={values.street}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="number"
                  type="text"
                  placeholder="Número"
                  value={values.number}
                  required
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth required sx={classes.inputOutlinedInput}>
                  <InputLabel id="neighborhood">Bairro</InputLabel>
                  <Select
                    labelId="neighborhood"
                    id="neighborhood"
                    name="neighborhood"
                    value={neighborhood?.id}
                    label="Bairro"
                    onChange={e => {
                      const v = ceps.find(cep => cep.id === e.target.value);
                      if (v) {
                        setneighborhood(v);
                        handleChange(v.name);
                      }
                    }}
                    required
                  >
                    {ceps.map((cep, index) => (
                      <MenuItem key={index} value={cep.id}>
                        {cep.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12}></Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={12}></Grid> */}
            </Grid>
            <Box m={2}>
              <Button fullWidth type="submit" loading={loadingSaveAddress}>
                {address ? 'Atualizar' : 'Cadastrar'}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default AddressForm;
