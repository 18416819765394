const styles = {
  headerSearch: {
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddding: 10,
    color: theme => theme.palette.grey[800],
    fontSize: 10,
    marginBottom: 30
  }
};
export default styles;
