import config from '../config/config';
const colors = {
  primary: {
    ligther: config.Colors.primary.main,
    ligth: config.Colors.primary.main,
    main: config.Colors.primary.main,
    medium: config.Colors.primary.main,
    dark: config.Colors.primary.main,
    darker: config.Colors.primary.main,
  },
  secundary: {
    ligther: '#EDA57B',
    ligth: '#EDA57B',
    main: '#EDA57B',
    medium: '#EDA57B',
    dark: '#EDA57B',
    darker: '#EDA57B',
  },
  success: {
    ligther: config.Colors.primary.main,
    ligth: config.Colors.primary.main,
    main: config.Colors.primary.main,
    medium: config.Colors.primary.main,
    dark: config.Colors.primary.main,
    darker: config.Colors.primary.main,
  },
  info: {
    ligther: '#EDA57B',
    ligth: '#EDA57B',
    main: '#EDA57B',
    medium: '#EDA57B',
    dark: '#EDA57B',
    darker: '#EDA57B',
  },
  warn: {
    ligther: '',
    ligth: '',
    main: '',
    medium: '',
    dark: '',
    darker: '',
  },

  dark: {
    ligther: '',
    ligth: '',
    main: '',
    medium: '',
    dark: '',
    darker: '',
  },

  grey: {
    ligther: '#F0F4F8',
    ligth: '',
    main: '',
    medium: '',
    dark: '',
    darker: '',
  },

  input: {
    text: '#A6BCD0',
  },
};
export default colors;
