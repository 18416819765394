import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Grid, Divider, ImageListItem } from '@mui/material';

import { getProductMedia } from '../../../services/exploreService';

import useStyles from './styles';

import ProductNoImage from '../../../resources/img/product_no_image.png';

function Image({ image, alt }) {
  const classes = useStyles;
  return <img src={image} alt={alt} style={classes.media} loading="lazy" />;
}

function ProductListRow({ product }) {
  const classes = useStyles;

  useEffect(() => {
    console.log(product);
  }, []);
  return (
    <>
      <Link to={`/product/${product.product_id}`}>
        <Grid container>
          <Grid item sx={classes.image}>
            {product.medias.length > 0 && (
              <ImageListItem>
                <Image
                  image={getProductMedia(product.medias[0].uri)}
                  alt={product.name}
                />
              </ImageListItem>
            )}
            {product.medias.length === 0 && (
              <Image image={ProductNoImage} alt={product.name} />
            )}
          </Grid>
          <Grid item sx={classes.containerDescription}>
            <Grid xs={12} item sx={classes.name}>
              {product.name}
            </Grid>
            <Grid xs={12} item sx={classes.price}>
              R$ {product.price}
            </Grid>
          </Grid>
        </Grid>
      </Link>
      <Divider light />
    </>
  );
}

export default ProductListRow;
