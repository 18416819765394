import React, { useState, useEffect } from 'react';

// components
import { Typography, Container, Box, CircularProgress } from '@mui/material';

import styles from './styles';
// services
import { getAddressById, getCeps } from '../../../services/accountService';

// custom components
import Form from './Form';
import { useParams } from 'react-router-dom';

function AddressessList({ address }) {
  const [activeAddress, setActiveAddress] = useState(null);
  const [filialCepData, setFilialCepData] = useState([]);

  const [initalLoading, setInitialLoading] = useState(true);
  const params = useParams();
  useEffect(() => {
    async function getData() {
      try {
        const ceps = await getCeps();
        setFilialCepData(ceps);
        if (params.id) {
          const activeAddress = await getAddressById(params.id);
          setActiveAddress(activeAddress);
        }
        setInitialLoading(false);
      } catch (err) {}
    }
    getData();
  }, []);
  return (
    <Container sx={{ p: 2 }}>
      <Box sx={styles.headerContainer}>
        <Typography variant="h5">Editar endereço</Typography>
      </Box>

      {initalLoading && <CircularProgress />}
      {!initalLoading && (
        <>
          <Form activeAddress={activeAddress} filialCepData={filialCepData} />
        </>
      )}
    </Container>
  );
}

export default AddressessList;
