import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

export default function IconStore({ color, className, ...props }) {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21.648"
        height="19.273"
        viewBox="0 0 21.648 19.273"
      >
        <g id="icon-store" transform="translate(0.5 0.5)">
          <path
            id="path"
            d="M148.2,769.354h-1.059V776.5h-2.916v-7.149h-4.233V776.5H128.612v-7.149h-1.058v-2.117l1.858-5.291h16.932l1.858,5.291Zm-11.124,4.233v-4.233h-5.55v4.233Z"
            transform="translate(-127.554 -758.23)"
            fill="none"
            stroke={color || '#748a9d'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            className={className}
          />
          <rect
            id="path-2"
            data-name="path"
            width="16.932"
            height="2.117"
            transform="translate(1.858)"
            fill="none"
            stroke={color || '#748a9d'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            className={className}
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
