import api from './api';

export async function getOrders() {
  const response = await api.get(`/customer/sale/orders`);

  return response.data;
}
export async function getOrder(id) {
  console.log(id);
  const response = await api.get(`/customer/sale/orders/${id}`);

  let data = response.data;
  // if(typeof data.filial_payment !== 'object') {
  //   data.filial_payment = JSON.parse(data.filial_payment)
  // }
  // if(typeof data.payment !== 'object') {
  //   data.payment = JSON.parse(data.payment)
  // }
  // if(typeof data.address_delivery !== 'object') {
  //   data.address_delivery = JSON.parse(data.address_delivery)
  // }
  // if(typeof data.payment_status !== 'object') {
  //   data.payment_status = JSON.parse(data.payment_status)
  // }
  // if(typeof data.items !== 'object') {
  //   data.items = JSON.parse(data.items)
  // }
  return data;
}
