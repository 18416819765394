import api from './api';

export async function postPayment(cart) {
  const response = await api.post(`/customer/sale/paymentweb`, cart);

  return response.data;
}

export async function checkout(products, total, address) {
  let qtdTotal = 0;

  products.forEach(p => {
    qtdTotal += p.qtd;
  });

  const cart = {
    products,
    qtdTotal,
    total,
  };
  console.log({ cart });
  const response = await api.post(`/customer/sale/check`, { cart });

  return response.data;
}

export async function getPaymentMethod() {
  const response = await api.get('/customer/account/formofpayment');

  return response.data;
}

export async function postCardPayment(card) {
  const data = {
    type: 'card',
    payment: card /* {
      "cardNumber": 123,
      "expirateDate": "",
      "securityCode": 123,
      "cardHolder": "italo j n paula"
    } */,
  };

  const response = await api.post('/customer/account/formofpayment', data);

  return response.data;
}

export async function getCards() {
  const response = await api.get('/customer/account/formofpayment');

  return response.data;
}

export async function paymentDelivery(cart, customer_obs) {
  console.log('-==========================CART==========================');
  console.log(cart);
  console.log(cart.payment);
  let subTotal = 0;

  let productsCart = cart.productsCart.map(p => {
    subTotal += parseFloat(p.price) * p.qtd;
    return {
      filial_id: p.filial_id,
      id: p.product_id,
      price: p.price,
      qtd: p.qtd,
    };
  });

  let total = subTotal + cart.deliveryPrice;
  console.log('==================================data');

  const response = await api.post('/customer/sale/paymentweb', {
    productsCart: productsCart,
    deliveryPrice: cart.deliveryPrice,
    checkout: {
      payments: [cart.payment],
      products: productsCart,
      productErrors: [],
      shipping: cart.shipping,
      total: {
        subTotal: subTotal,
        total: total,
      },
    },
    address: cart.address,
    payment: cart.payment,
    customer_obs,
  });
  return response.data;
}
