import React, { useState, useEffect } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

// components material-ui
import { Container, makeStyles, Typography } from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
// react-router-dom
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';
// actions
import { setCheckout } from '../../redux/cart/actions';
// custom compoments
import Button from '../../components/Button';

// api services
import { checkout } from '../../services/paymentService';

import config from '../../config/config';

const useStyles = {
  container: {
    height: 224,
    borderRadius: 1,
    background: '#f0f4f8',
    padding: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  subTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: theme => theme.palette.grey[600],
  },
  TotalContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: theme => theme.palette.grey[600],
  },
  buttonContainer: {
    marginTop: 3,
    marginBottom: 2,
  },
};

function Footer() {
  const classes = useStyles;
  const navigate = useNavigate();
  const { user } = useAuth();
  const dispatch = useDispatch();

  const cart = useSelector(state => state.cart);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const deliveryPriceState = useSelector(state => state.cart.deliveryPrice);
  const [deliveryPrice, setDeliveryPrice] = useState(deliveryPriceState);
  const [checkoutLoading, setCheckoutLoading] = useState(false);

  useEffect(() => {
    let subTotal = 0;

    cart.productsCart.forEach(product => {
      subTotal += parseFloat(product.price) * product.qtd;
    });

    if (subTotal === 0) setDeliveryPrice(0);
    else setDeliveryPrice(1.99);

    setSubTotal(subTotal);

    setTotal(subTotal + deliveryPrice);
  }, [deliveryPrice, cart]);

  const handleCheckout = async () => {
    // let response;
    // if (user) {
    //   response = await checkout(cart.productsCart, total);
    //   setCheckoutLoading(false);
    //   if (response.productErrors.length > 0) {
    //     alert('Há produtos fora de estoque');
    //     return null;
    //   }
    // }

    // dispatch(setCheckout(response));
    navigate('/checkout');
  };
  return (
    <Container maxWidth="xs" sx={classes.container}>
      <div style={classes.subTitleContainer}>
        <Typography>SubTotal</Typography>
        <Typography>
          {Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(Number(subTotal))}
        </Typography>
      </div>
      <div style={classes.subTitleContainer}>
        <Typography>Entrega</Typography>
        <Typography>
          {Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(Number(deliveryPrice))}
        </Typography>
      </div>
      <div style={classes.TotalContainer}>
        <Typography variant="h5">Total</Typography>
        <Typography variant="h5">
          {Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(Number(total))}
        </Typography>
      </div>
      <div style={classes.buttonContainer}>
        <Button
          startIcon={<ArrowForwardOutlinedIcon />}
          disabled={cart.productsCart.length === 0}
          loading={checkoutLoading}
          onClick={() => {
            setCheckoutLoading(true);
            handleCheckout();
          }}
        >
          FINALIZAR PEDIDO
        </Button>
      </div>
      <div>
        <Link to={config.toSales}>Continuar comprando</Link>
      </div>
    </Container>
  );
}

export default Footer;
