import React, { useContext, useEffect, useState } from 'react';

// context
import { useNavigate } from 'react-router-dom';

// components material-ui
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import { Link } from 'react-router-dom';

// icons
import { useDispatch } from 'react-redux';
import AuthContext from '../../contexts/Auth';
import useStyles from './styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// redux
import { deleteAllProduct } from '../../redux/cart/actions';

// api
import { getUser } from '../../services/authService';

function Account() {
  const classes = useStyles;

  const dispatch = useDispatch();

  const { user: userProps, signOut } = useContext(AuthContext);
  const navigate = useNavigate();

  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState('');

  useEffect(() => {
    async function fetchData() {
      if (!userProps) {
        navigate('/login?redirect=account');
        return;
      }
      try {
        const response = await getUser(userProps.id);
        console.log(response);

        setAccount(response.user);
        setLoading(false);
        setFirstName(response.profile[0].value);
      } catch (err) {
        console.log(err);
        if (err?.response?.status === 401) {
          signOut();
        }
      }
    }
    fetchData();
  }, [userProps]);

  return (
    <Container maxWidth="sm" sx={classes.container}>
      <Box sx={classes.headerContainer}>
        <Typography variant="h6" component="h1">
          Bem Vindo
        </Typography>
        <Typography variant="h5" component="h2">
          {firstName}
        </Typography>
      </Box>

      {/* <Orders /> */}

      {/* <Container maxWidth="sm" sx={classes.list}> */}
      {loading && <CircularProgress />}
      {!loading && (
        <>
          <List>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/orders">
                <ListItemText primary="Meus Pedidos" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/account/edit">
                <ListItemText primary="Minha conta" />
              </ListItemButton>
            </ListItem>
          </List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/account/address">
              <ListItemText primary="Endereços" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Entrar em contato via Whatsapp" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Condições e Termos de uso " />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Politica de Privacidade" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Politica de Devolução" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              variant="body2"
              onClick={() => {
                dispatch(deleteAllProduct());
                signOut();
              }}
              sx={classes.ButtonGoOut}
            >
              Sair Da Minha Conta
            </ListItemButton>
          </ListItem>
        </>
      )}

      {/* <Typography
          variant="body2"
          onClick={() => {
            dispatch(deleteAllProduct());
            signOut();
          }}
        >
          Sair da Minha Conta
        </Typography> */}
    </Container>
  );
}

export default Account;
