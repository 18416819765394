import React from 'react';

import { Typography } from '@mui/material';

import useWindowSize from '../../../contexts/WindowSize';

import useStyles from './styles';

function Footer() {
  const classes = useStyles;

  const window = useWindowSize();
  return (
    <div style={classes.container}>
      <Typography variant="body1" component="body1">
        Desenvolvido por CVO {window.width}px
      </Typography>
    </div>
  );
}

export default Footer;
