export default {
  container: {
    paddingTop: 4,
  },
  form: {
    flex: 1,
    minHeight: 'calc(85vh - 58px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  headerContainer: {
    paddingBottom: 3,
  },
  list: {
    backgroundColor: theme => theme.palette.grey[300],
    borderRadius: 1,
    padding: 2,
    justifyContent: 'space-between',
  },
  listItem: {
    justifyContent: 'space-between',
    display: 'flex',
    padding: 1,
    color: theme => theme.palette.black,
    background: '#F0F4F8',
    borderRadius: theme => theme.spacing(1),
  },
  listItemEdit: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: theme => theme.palette.primary.main,
    },
  },
  money: { display: 'flex', paddingRight: 2, justifyContent: 'flex-start' },
  edit: { display: 'flex', paddingRight: 2, justifyContent: 'flex-end' },
};
