export default {
  filial_id: '1', //'14',
  organization_id: '1', //'18',
  // baseUrl: `https://comerciovarejistaonline.com.br/prod`,
  //baseUrl:  `localhost:3334`,
  siteTitle: 'HortiFruti Top De Linha',
  siteTitleAbbreviated: 'TdL',
  slogan: 'HortiFruti Top De Linha',
  Colors: {
    primary: {
      ligther: '#7BED8D',
      ligth: '#7BED8D',
      main: '#7BED8D',
      medium: '#7BED8D',
      dark: '#7BED8D',
      darker: '#7BED8D',
    },
  },
  toSales: '/catalog',
  menu: [
    {
      label: 'Início',
      to: '/',
    },
    {
      label: 'Catálogo',
      to: '/catalog',
    },
    {
      label: 'Carrinho',
      to: '/cart',
    },
    {
      label: 'Conta',
      to: '/account',
    },
  ],
};
