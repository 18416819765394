import React from 'react';

// coomponents material-ui
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// icons
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const useStyles = {
  container: {
    width: 152,
    height: 34,
    borderRadius: 1,
    background: '#fff',
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'space-around;',
    alignItems: 'center',
  },
  button: {
    border: 'none',
    color: theme => theme.palette.grey[400],
    borderRadius: 1,
    height: 34,
    '&:hover': {
      border: 'none',
      backgroundColor: theme => theme.palette.grey[200],
      color: '#fff',
    },
  },
  tetxContainer: {
    width: '10%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textValue: {
    fontSize: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const SelectquantityTabletComponent = ({ actionMinus, value, actionAdd }) => {
  const classes = useStyles;
  return (
    <div style={classes.container}>
      <div>
        <Button sx={classes.button} onClick={() => actionMinus()}>
          <RemoveOutlinedIcon />
        </Button>
      </div>
      <div style={classes.tetxContainer}>
        <Typography variant="body2" component="body2" sx={classes.textValue}>
          {value || '0'}
        </Typography>
      </div>
      <div>
        <Button sx={classes.button} onClick={() => actionAdd()}>
          <AddOutlinedIcon />
        </Button>
      </div>
    </div>
  );
};

export default SelectquantityTabletComponent;
