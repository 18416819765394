import React from 'react';

import { Button } from '@mui/material';
import IconCart from '../../resources/icons/IconCart';
import { Link } from 'react-router-dom';

import styles from './styles';

export default function ButtonCart() {
  return (
    <Button
      componet={Link}
      to="/"
      color="primary"
      variant="contained"
      sx={styles.button}
    >
      <IconCart color="#FFFFFF" />
    </Button>
  );
}
