import React, { useState, useEffect } from 'react';

// contexts

// router
import { useNavigate, useParams } from 'react-router-dom';

// compoennts material-ui
import { Grid, Container, Typography, CircularProgress } from '@mui/material';
// api services
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import { TextField } from '@mui/material';
// import { useCart } from '../../contexts/Cart';
import { useDispatch } from 'react-redux';
import { getProduct, getProductMedia } from '../../services/exploreService';
import { addProduct } from '../../redux/cart/actions';
// components
import useStyles from './styles';
import Button from '../../components/Button';
import SelectQuantity from '../../components/SelectQuantity';

import Food from './Food';

function ProductPage() {
  const dispatch = useDispatch();

  const classes = useStyles;
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [product, setProduct] = useState(null);
  const [medias, setMedias] = useState([]);

  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(0);

  const [priceAddtional, setPriceAddtional] = useState([]);

  const [removeOptional, setRemoveOptional] = useState([]);

  const [obsCustomer, setObsCustomer] = useState('');

  useEffect(() => {
    async function load() {
      try {
        const response = await getProduct(id);
        if (response.product) setProduct(response.product);
        console.log(response);
        if (response.medias) setMedias(response.medias);
        setPrice(parseFloat(response.product.price));
        setLoading(false);
      } catch (e) {
        var message = e.message;
        if (e.response && e.response.data.message)
          message = e.response.data.message;
        setErrorMessage(message);
        console.log(e);
        setLoading(false);
      }
    }
    load();
  }, [id]);
  useEffect(() => {
    if (product) {
      let price = product.price;
      console.log(priceAddtional);
      priceAddtional.map(i => {
        price = parseFloat(price) + parseFloat(i.price);
      });

      setPrice(price * quantity);
    }
  }, [product, quantity, priceAddtional]);

  const handleButtonBuy = () => {
    if (product) {
      const cartProduct = product;
      if (medias && medias.length > 0 && medias[0].uri) {
        cartProduct.media = medias[0].uri;
      }
      cartProduct.qtd = quantity;
      cartProduct.obsCustomer = obsCustomer;
      cartProduct.price = price;
      if (product.product_type === 'food_simple') {
        console.log(removeOptional);
        cartProduct.food = {
          addtionals: priceAddtional,
          removeOptionals: removeOptional,
        };
      }
      dispatch(addProduct(cartProduct));
      navigate('/cart');
    }
  };
  return (
    <div>
      <div style={classes.headerSearch} />
      <Container maxWidth="lg">
        {loading && <CircularProgress />}
        {!loading && errorMessage && (
          <>
            <Typography>{errorMessage}</Typography>
            <a
              onClick={() => {
                // history.goBack()
              }}
            >
              <Typography>Voltar</Typography>
            </a>
          </>
        )}
        {!loading && product && (
          <Grid container spacing={3}>
            <Grid item sm={12} xs={12} md={6} lg={6} sx={classes.sliderProduct}>
              {medias && medias.length > 0 && (
                <img
                  style={classes.sliderProduct}
                  src={getProductMedia(medias[0].uri)}
                  alt="teste"
                />
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={6} lg={6}>
              <div style={classes.details}>
                <Typography variant="h4" component="h1" sx={classes.title}>
                  {product.name}
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={classes.shortDescription}
                >
                  {product.description}
                </Typography>
                <div>
                  {product.product_type === 'food_simple' && (
                    <>
                      <Food
                        food={product.food}
                        setPriceAddtional={a => {
                          let adds = [];
                          a.map(i => {
                            if (i.checked) adds.push(i);
                          });
                          setPriceAddtional(adds);
                        }}
                        setRemoveOptional={a => {
                          let removes = [];
                          a.map(i => {
                            if (!i.checked) removes.push(i);
                          });
                          setRemoveOptional(removes);
                        }}
                      />
                      <Typography variant="body" gutterBottom>
                        Observações
                      </Typography>
                      <TextField
                        id="obs-procut-customer"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={obsCustomer}
                        onChange={e => setObsCustomer(e.target.value)}
                      />
                    </>
                  )}
                </div>

                <div style={classes.quantity}>
                  <Typography sx={classes.price} variant="h6" gutterBottom>
                    {price &&
                      Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(Number(price))}
                  </Typography>
                  <SelectQuantity
                    value={quantity}
                    actionMinus={() => {
                      if (quantity > 1) setQuantity(quantity - 1);
                    }}
                    actionAdd={() => {
                      setQuantity(quantity + 1);
                    }}
                  />
                </div>

                <Button
                  fullWidth
                  sx={classes.buttonCart}
                  startIcon={<AddShoppingCartOutlinedIcon />}
                  onClick={() => handleButtonBuy()}
                >
                  Comprar
                </Button>

                <div style={classes.sectionDescription}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={classes.descriptionTitle}
                  >
                    Descrição Completa
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={classes.descriptionText}
                  >
                    {product.description}
                    <br />
                    SKU: {product.sku}
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  );
}

export default ProductPage;
