import React, { useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { Typography, CircularProgress, TextField, Box } from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import useStyles from './styles';
import Button from '../../components/Button';

// components material-ui

// context
import AuthContext from '../../contexts/Auth';

function Login() {
  const classes = useStyles;
  const { user, errorMessage, loading, register } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);

  const handleSubmit = async data => {
    const { first_name, last_name, email, password } = data;
    if (first_name && last_name && email && password)
      await register(email, password, first_name, last_name);
  };
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
    },
    onSubmit: values => {
      handleSubmit(values);
    },
  });
  return (
    <div style={classes.container}>
      <Typography variant="h4" sx={classes.title}>
        Criar uma conta
      </Typography>
      <Box component="form" onSubmit={formik.handleSubmit} sx={classes.form}>
        {errorMessage && <span style={classes.error}>{errorMessage}</span>}
        <TextField
          placeholder="Primeiro nome"
          type="text"
          name="first_name"
          required
          sx={classes.input}
          onChange={formik.handleChange}
          value={formik.values.first_name}
        />
        <TextField
          placeholder="Segundo nome"
          type="text"
          name="last_name"
          required
          sx={classes.input}
          onChange={formik.handleChange}
          value={formik.values.last_name}
        />
        <TextField
          placeholder="Email"
          type="email"
          name="email"
          required
          sx={classes.input}
          onChange={formik.handleChange}
          value={formik.values.email}
        />
        <TextField
          placeholder="Senha"
          type="text"
          name="password"
          required
          sx={classes.input}
          onChange={formik.handleChange}
          value={formik.values.password}
        />
        <Button
          type="submit"
          startIcon={<ArrowForwardOutlinedIcon />}
          disabled={loading}
          sx={classes.button}
        >
          Criar
        </Button>
        {loading && <CircularProgress size={24} sx={classes.buttonProgress} />}
      </Box>
      <Link to="/login">
        <p sx={classes.link}>Fazer login</p>
      </Link>
      <Link to="/">
        <p sx={classes.link}>Mais tarde</p>
      </Link>
    </div>
  );
}

export default Login;
