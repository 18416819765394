import React, { useEffect, useState } from 'react';

// redux
import { useSelector } from 'react-redux';
// components material-ui
import { Typography, Box } from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
// custom compoments
import Button from '../../../components/Button';
import useStyles from './styles';
// api services
import { paymentDelivery } from '../../../services/paymentService';
import api from '../../../services/api';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clear } from '../../../redux/cart/actions';

function OrderTotal() {
  const classes = useStyles;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cart = useSelector(state => state.cart);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const deliveryPriceState = useSelector(state => state.cart.deliveryPrice);
  const [deliveryPrice, setDeliveryPrice] = useState(deliveryPriceState);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [customer_obs, setCustomer_obs] = useState('');

  const [errorMessage, setErrorMessage] = useState(false);

  const handlePayment = async () => {
    try {
      console.log(cart);
      await paymentDelivery(cart, customer_obs)
        .then(res => {
          setPaymentLoading(false);
          dispatch(clear());
          console.log(res);
          navigate(`/order/${res.id}`);
        })
        .catch(err => {
          setPaymentLoading(false);
          setErrorMessage(err.message);
        });

      // postPayment(cart)
    } catch (err) {
      setErrorMessage(err.message);
      setPaymentLoading(false);
    }
  };
  useEffect(() => {
    let subTotal = 0;

    cart.productsCart.forEach(product => {
      subTotal += parseFloat(product.price) * product.qtd;
    });

    if (subTotal === 0) setDeliveryPrice(0);
    else setDeliveryPrice(1.99);

    setSubTotal(subTotal);

    setTotal(subTotal + deliveryPrice);
  }, [deliveryPrice, cart]);

  return (
    <Box sx={classes.container}>
      <Typography variant="subtitle1" sx={classes.subTitle}>
        Resumo do pedido
      </Typography>
      <Box sx={classes.totalContainer}>
        <Box sx={classes.totalValues}>
          <Typography variant="subtitle1">Sub-total</Typography>
          <Typography variant="subtitle">
            {Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(Number(subTotal))}
          </Typography>
        </Box>
        <Box sx={classes.totalValues}>
          <Typography variant="subtitle1">Entrega</Typography>
          <Typography variant="subtitle">
            {Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(Number(deliveryPrice))}
          </Typography>
        </Box>
        <Box sx={classes.totalValues}>
          <Typography variant="h6">Total</Typography>
          <Typography variant="h6">
            {Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(Number(total))}
          </Typography>
        </Box>

        {cart.payment && cart.payment.type && cart.payment.type === 'money' && (
          <Box sx={classes.totalValues}>
            <Typography variant="subtitle">Troco: </Typography>
            <Typography variant="subtitle">
              {Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(Number(cart.payment.change))}
            </Typography>
          </Box>
        )}
      </Box>
      <Button
        startIcon={<ArrowForwardOutlinedIcon />}
        fullWidth
        sx={classes.submit}
        onClick={() => {
          setErrorMessage(false);
          handlePayment();
          setPaymentLoading(true);
        }}
        loading={paymentLoading}
        disabled={
          cart.productsCart.length === 0 ||
          !cart.address ||
          !cart.payment ||
          (cart.payment.type === 'money' && cart.payment.change < total)
        }
      >
        Finalizar compra
      </Button>
      {errorMessage && <div>{errorMessage}</div>}
    </Box>
  );
}

export default OrderTotal;
