import React from 'react';

import TopNav from './Nav';
import Footer from './footer';
import useStyles from './styles';
import { Outlet } from 'react-router-dom';
import useWindowSize from '../../contexts/WindowSize';

export default function Template() {
  const classes = useStyles;
  const window = useWindowSize();
  return (
    <>
      <TopNav />
      <div style={classes.main(window.width)}>
        <Outlet />
      </div>
    </>
  );
}
