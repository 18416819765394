import React, { useState, useEffect } from 'react';
// components @material-ui
import {
  Container,
  Typography,
  Grid,
  // Fade,
  Box,
  Modal,
  Backdrop,
  Dialog,
} from '@mui/material';

/// redux
import { useDispatch, useSelector } from 'react-redux';
import { setAddress } from '../../../redux/cart/actions';
import useStyles from './styles';
// custom local components
import AddressForm from './AddressForm';
import ListAddress from './ListAddress';

/// api
import { getAddress, getCeps } from '../../../services/accountService';

// contexts
//import { useAuth } from '../../../contexts/Auth';

function DeliveryAddress() {
  const { address } = useSelector(state => state.cart);
  const dispatch = useDispatch();
  const classes = useStyles;
  //const { user } = useAuth();

  const [activeAddress, setActiveAddress] = useState(null);
  const [listAddress, setListAddress] = useState([]);
  const [lisCeps, setLisCeps] = useState([]);
  const [loadingList, setLoadingList] = useState(true);
  const [isVisibleList, setIsVisibleList] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [addressEdit, setAddressEdit] = useState(null);

  useEffect(() => {
    if (listAddress.length === 0) setIsEdit(true);
  }, [listAddress]);
  useEffect(() => {
    async function loading() {
      let res = await getAddress();

      let ceps = await getCeps();

      setListAddress(res.address);
      setLisCeps(ceps);
      setActiveAddress(res.activeAddress);
      setLoadingList(false);
      setIsEdit(false);
    }
    loading();
  }, []);

  /**
   * @description  change active address
   * @param {Int} id
   */
  const handleClickSelectAddress = id => {
    setActiveAddress({ ...activeAddress, id });
    dispatch(setAddress({ ...activeAddress, id }));
  };

  return (
    <Container sx={classes.container}>
      <Typography variant="subtitle1" sx={classes.subTitle}>
        Endereço de entrega
      </Typography>
      <Grid container sx={classes.item}>
        <Grid item xs={8}>
          {address && (
            <Typography>
              {address?.street}, {address?.number}, {address?.neighborhood},{' '}
              {address?.zip_code}
            </Typography>
          )}
          {!address && (
            <Typography>{listAddress.length > 0 ? 'Selecione ou cadastre' : 'Cadastre'} um endereço</Typography>
          )}
        </Grid>
        {
          listAddress.length > 0 &&
          <Grid item >
            <Typography
              onClick={() => {
                console.log(address);
                setIsVisibleList(true);
              }}
              sx={classes.textClickAction}
            >
              Alterar
            </Typography>
          </Grid>
        }

      </Grid>
      <ListAddress
        listAddress={listAddress}
        open={isVisibleList}
        setOpen={setIsVisibleList}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        setAddressEdit={setAddressEdit}
      />

      <Typography
        onClick={() => {
          setAddressEdit(null);
          setIsEdit(true);
        }}
        sx={classes.textClickAction}
      >
        Adicionar novo
      </Typography>
      {!loadingList && (
        <>
          <Dialog
            open={isEdit || false}
            onClose={() => setIsEdit(false)}
            // closeAfterTransition
            BackdropComponent={Backdrop}
          // BackdropProps={{
          //   timeout: 500,
          // }}
          >
            <Box sx={classes.modalContent}>
              {/* <Fade in={isEdit}> */}
              <Typography variant='h6' sx={classes.titleModal}>
                {address ? 'Atualizar' : 'Cadastrar'} Endereço
              </Typography>
              {isEdit && (
                <AddressForm
                  address={addressEdit}
                  ceps={lisCeps}
                  setIsEdit={setIsEdit}
                />
              )}
              <Typography
                onClick={() => {
                  setIsEdit(false);
                }}
                sx={classes.textClickAction}
              >
                Cancelar
              </Typography>
              {/* </Fade> */}
            </Box>
          </Dialog>
        </>
      )}
    </Container>
  );
}

export default DeliveryAddress;
