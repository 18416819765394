import React, { useEffect } from 'react';

// redux
import { useDispatch } from 'react-redux';

import { Box, Typography, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import {
  addProduct,
  deleteProduct,
  minusProduct,
} from '../../../redux/cart/actions';
import NoImage from '../../../resources/img/product_no_image.png';
import SelectQuantity from '../../SelectQuantity';
import styles from './syles';
import { getProductMedia } from '../../../services/exploreService';

import useWindowSize from '../../../contexts/WindowSize';

function ProductCartGrid({ product, ...props }) {
  const windowSize = useWindowSize();
  const classes = styles;
  console.log(product);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(product);
  }, []);
  return (
    <Box sx={classes.containerRow}>
      <Box sx={classes.sectionImage}>
        {product?.medias?.[0] && (
          <img
            style={classes.media}
            alt={'imagem do produto'}
            src={getProductMedia(product.medias[0]?.uri)}
          />
        )}

        {!product?.medias?.[0] && (
          <img style={classes.media} alt={'imagem do produto'} src={NoImage} />
        )}
      </Box>
      <Box sx={classes.sectionBottom}>
        <Box>
          <Typography sx={classes.productName}>{product.name}</Typography>
          <Typography sx={classes.productDetails} variant="body2">
            {product.food &&
              product.food.addtionals &&
              product.food.addtionals.map((item, i) => <>{item.name}</>)}
          </Typography>
          <Typography sx={classes.productDetails} variant="body2">
            {product.food &&
              product.food.removeOptionals &&
              product.food.removeOptionals.length > 0 &&
              'Sem '}
            {product.food &&
              product.food.removeOptionals &&
              product.food.removeOptionals.map((item, i) => <>{item.name}, </>)}
          </Typography>
        </Box>
        <Box sx={classes.priceContainer}>
          <Typography sx={classes.productPrice}>
            {Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(Number(+product.price * product.qtd))}
          </Typography>

          <SelectQuantity
            value={product.qtd}
            actionAdd={() => dispatch(addProduct(product))}
            actionMinus={() => dispatch(minusProduct(product))}
          />
        </Box>
      </Box>

      <IconButton
        variant="contained"
        sx={classes.trash}
        onClick={() => dispatch(deleteProduct(product))}
      >
        <DeleteOutlinedIcon color="secondary" />
      </IconButton>
    </Box>
  );
}

export default ProductCartGrid;
