import {
  ADD_PRODUCT,
  DELETE_PRODUCT,
  MINUS_PRODUCT,
  DELETE_ALL_PRODUCT,
  SET_CHECKOUT,
  SET_ADDRESS,
  SET_PAYMENT,
  CLEAR,
} from './actionTypes';

export function addProduct(product) {
  return {
    type: ADD_PRODUCT,
    product,
  };
}
export function deleteProduct(product) {
  return {
    type: DELETE_PRODUCT,
    product,
  };
}

export function minusProduct(product) {
  return {
    type: MINUS_PRODUCT,
    product,
  };
}

export function deleteAllProduct() {
  return {
    type: DELETE_ALL_PRODUCT,
  };
}

export function setCheckout(checkout) {
  return {
    type: SET_CHECKOUT,
    checkout,
  };
}

export function setPayment(payment) {
  return {
    type: SET_PAYMENT,
    payment,
  };
}

export function setAddress(address) {
  return {
    type: SET_ADDRESS,
    address,
  };
}
export function clear() {
  return {
    type: CLEAR,
  };
}
