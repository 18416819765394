import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

export default function IconStore({ color }) {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19.692"
        height="18.686"
        viewBox="0 0 19.692 18.686"
      >
        <g id="icon-account" transform="translate(0.5 0.5)">
          <path
            id="path"
            d="M350.257,584.929a9.343,9.343,0,0,0-7.167-9.081v-1.039a4.1,4.1,0,1,0-4.358,0v1.039a9.343,9.343,0,0,0-7.167,9.081"
            transform="translate(-331.565 -567.243)"
            fill="none"
            stroke={color || '#a6bcd0'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
