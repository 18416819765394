import api from './api';

export async function getNeighborhood() {
  const response = await api.get('/customer/address');

  return response.data;
}

export async function getMyAccount() {
  const response = await api.get(`/customer/account/account/`);

  return response.data;
}
export async function putMyAccount(id, data) {
  const response = await api.put(`/customer/account/account/${id}`, data);

  return response.data;
}
export async function getAddress() {
  const response = await api.get('/customer/account/address');

  return response.data;
}

export async function getAddressById(id) {
  const response = await api.get(`/customer/account/address/${id}`);

  return response.data;
}
export async function getCeps() {
  const response = await api.get('/customer/deliveryceps');

  return response.data;
}

export async function postAddress(data) {
  data.country = 'BR';
  console.log(data);
  const response = await api.post('/customer/account/address', data);

  return response.data;
}

export async function putAddress(id, address) {
  address.country = 'BR';
  console.log(id, address);

  delete address.customerFilial;
  // const filial_delivery = address.filial_delivery;
  delete address.filial_delivery;
  address.number = +address.number;

  console.log(JSON.stringify(address));
  const response = await api.put(`/customer/account/address/${id}`, address);
  console.log(response.data);
  return response.data;
}
