import React from 'react';

// components material-ui
import { Container, Grid, Box, Typography } from '@mui/material';

// custom components
import useStyles from './styles';
import DeliveryAddress from './DeliveryAddress';
import Speed from './Speed';
import PaymentMethod from './PaymentMethod';
import OrderTotal from './OrderTotal';
import Login from './Login';

import { useAuth } from '../../contexts/Auth';

function Checkout() {
  const classes = useStyles;

  const { user } = useAuth();

  if (!user) {
    return (
      <Container maxWidth="lg">
        <Grid
          container
          sx={classes.container}
          justifyContent="center"
          alignItems="center"
        >
          <Grid lg={6} xs={12} md={12}>
            <Login redirect="checkout" />
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={classes.container}>
      <Box>
        <Typography component="h1" variant="h5" align="center" p={2}>
          Checkout
        </Typography>
        <Grid container>
          <Grid item lg={4} xs={12} md={12}>
            <DeliveryAddress />
          </Grid>
          <Grid item lg={4} xs={12} md={12}>
            <PaymentMethod />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container>
          <Grid item lg={4} xs={12} md={12}>
            <OrderTotal />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Checkout;
