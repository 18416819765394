import api from './api';

import baseUrl from './baseUrl';

export async function getCategories() {
  const response = await api.get(`/customer/explore`);

  const data = response.data;

  let newData = [];

  data.forEach(cat => {
    newData.push({
      products: cat.products.slice(0, 10),
      ...cat,
    });
  });

  return newData;
}
export async function getCatalog() {
  const response = await api.get(`/customer/catalog`);
  console.log(response.data);
  return response.data;
}

export async function postSearch({ category, search, page = 0 }) {
  const body = {};

  if (category) body.category = category;

  if (search) body.search = search;

  body.page = page;

  const response = await api.post(`/customer/searchProducts`, body);

  return response.data;
}

export async function getProduct(id) {
  const response = await api.get(`/customer/product/${id}`);
  return response.data;
}
export function getProductMedia(uri) {
  // console.log(`${baseUrl}/customer/${uri}`);
  return `${baseUrl}/customer/${uri}`;
}
