import React, { Component } from 'react';

import Footer from './../../components/Template/footer';

import Slider from '../../components/Slider';

export class Home extends Component {
  render() {
    return (
      <div>
        <Slider />
        <Footer />
      </div>
    );
  }
}

export default Home;
