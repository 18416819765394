import { createTheme } from '@mui/system';
const theme = createTheme();
export default {
  containerRow: {
    width: '685px',
    maxWidth: '100%',
    borderRadius: 8,
    background: '#f0f4f8',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 1,
    paddingTop: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  containerColumn: {
    width: 'auto',
    maxWidth: '100%',
    borderRadius: 8,
    background: '#f0f4f8',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 1,
    paddingTop: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  trash: {
    fontSize: 23,
    //alignSelf: 'flex-end',
    // margin: 1,
    marginTop: -9,
    cursor: 'pointer',
    '&:hover': {
      color: theme => theme.palette.error.main,
    },
  },
  media: {
    minHeight: 60,
    maxHeight: 60,
    maxWidth: 60,
    objectFit: 'cover',
  },
  sectionImage: {
    paddingLeft: 2,
    minWidth: 90,
    maxWidth: 90,
  },
  sectionBottom: {
    flex: 1,
  },
  productName: {
    color: theme => theme.palette.grey[700],
    display: 'flex',
    justifyContent: 'center',
  },
  productList: {
    color: theme => theme.palette.grey[700],
    paddingTop: 10,
    marginRight: 10,
    justifyContent: 'center',
  },
  productDetails: {
    color: theme => theme.palette.grey[500],
    marginRight: 5,
    justifyContent: 'center',
  },
  productPrice: {
    color: theme => theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    marginRight: '5%',
  },
  buttonCart: {
    backgroundColor: theme => theme.palette.primary.main,
    borderRadius: 8,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
  },
};
