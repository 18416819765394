import { createTheme } from '@mui/material/styles';

import Colors from './Colors';
//import config from '../config/config';
export default createTheme({
  palette: Colors,
  breakpoints: {
    values: {
      xs:0,
      sm: 600,
      md: 800,
      lg: 1200,
      xl: 1536,
    },
  },
});
;
