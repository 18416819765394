import React from 'react';

// components material-ui
import { Typography } from '@mui/material';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

// custom components
import Checkbox from '../../../components/form/CheckBox';
import useStyles from './styles';

function Speed() {
  const classes = useStyles;
  return (
    <div style={classes.container}>
      <Typography variant="subtitle1" sx={classes.subTitle}>
        Tempo de entrega
      </Typography>
      <div style={classes.list}>
        <div style={classes.item}>
          <div style={classes.icon}>
            <LocalShippingOutlinedIcon />
          </div>
          <Typography variant="subTitle2">Principal</Typography>
          <Typography variant="body2">40 min</Typography>
          <Checkbox defaultChecked />
        </div>
      </div>
    </div>
  );
}

export default Speed;
