import { createTheme } from '@mui/system';
const theme = createTheme();

export default {
  headerRoot: {
    height: 64,
    borderRadius: '0px 0px 36px 36px',
    background: '#fff',
    boxShadow: '0px 5px 30px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  container: {
    display: 'flex',
    alignItems: 'space-between',
    justifyContent: 'space-around',
    flexDirection: 'column',
    height: 'calc(100vh - 55px)',
    maxHeight: '92vh',
    overflow: 'none',
  },
  productListContainer: {
    flex: 6,
    display: 'block',
    marginTop: 2
  },
  productListItem: {
    mt: 1,
    mb: 1
  },
  productListEmpty: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 3,
  },
  productListEmptyTitle: {
    display: 'flex',
    width: '100%',
    justifycontent: 'center',
    alignItems: 'center',
  },
};
