import React, { useEffect, useRef } from 'react';
// import { useField } from '@unform/core';
import useStyles from './styles';

export default function InputText({ name, startIcon, ...rest }) {
  const inputRef = useRef(null);

  const classes = useStyles;

  // const { fieldName, defaultValue, registerField, error } = useField(name);

  // useEffect(() => {
  //   registerField({
  //     name: fieldName,
  //     ref: inputRef.current,
  //     path: 'value',
  //   });
  // }, [fieldName, registerField]);

  return (
    <div style={classes.container}>
      {startIcon && <div style={classes.icon}>{startIcon}</div>}
      <input
        // id={fieldName}
        ref={inputRef}
        // defaultValue={defaultValue}
        className={startIcon ? classes.inputIcon : classes.input}
        {...rest}
      />
      {/* {error && <span style={classes.error}>{error}</span>} */}
    </div>
  );
}
