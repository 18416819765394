import { makeStyles } from '@mui/material';

export default {
  container: {
    borderWidth: 2,
    borderColor: 'white',
    padding: 10,
    BorderRadius: 8,
    minWidth: 150,
    minHeight: 250,
    maxWidth: 200,
    maxHeight: 300,
    '&:hover': {
      // borderColor: theme => theme.palette.grey[400],
    },
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.07)',
    cursor: 'pointer',
  },
  enterpriseName: {
    color: theme => theme.palette.primary.main,
    fontSize: 15,
  },
  media: {
    backgroundColor: 'white',
    minHeight: 115,
    minWidth: 115,
  },
  productName: {
    fontSize: 20,
    color: theme => theme.palette.grey[700],
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 10,
  },
  productPrice: {
    fontSize: 15,
    color: theme => theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 15,
  },
  buttonCart: {
    backgroundColor: theme => theme.palette.primary.main,
    borderRadius: 8,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
  },
};
