// import Colors from './../../resources/Colors'
import { makeStyles } from '@mui/material/styles';
import green from '@mui/material/colors/green';

export default {
  container: {
    width: '100%',
  },
  buttonPrimary: {
    backgroundColor: theme => theme.palette.primary.main,
    minWidth: 315,
    minHeight: 50,
    borderRadius: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    cursor: 'pointer',
    color: '#FFFFFF',
    fontSize: 19,
    fontWeight: 'bold',
    margingTop: 10,
    margingBottom: 10,
    '&.Mui-disabled': {
      backgroundColor: theme => theme.palette.grey[300],
    },
  },
  textbutton: {},
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    marginTop: -35,
    marginLeft: 152,
  },
};
