export default {
  container: {
    marginTop:1,
  },
  header: {
    display: 'flex',
    marginBottom: 2,
  },
  headerTitle: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  companyInfoContainer: {
    // backgroundColor: theme => theme.palette.grey[200],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 4,
  },
  logo: {
    width: 76,
    height: 76,
    borderRadius: 38,
    backgroundColor: '#FFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 1,
  },
  list: {},
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '100%',
    overflowX: 'auto',
    marginBottom: 2,
    paddingBottom: 2,

  },
  categoryName: {
    color: '#000',
    '& span': {
      fontSize: 10,
    },
    '&:hover': {
      color: theme => theme.palette.primary.main,
    },
  },
};
