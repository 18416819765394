import React, { useState, useEffect } from 'react';

// components material-ui
import { Typography, Container, Grid, CircularProgress } from '@mui/material';

// api services
// import { Link } from 'react-router-dom';
import { getCatalog } from '../../services/exploreService';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// router
// import axios from 'axios';

import useStyles from './styles';
import ProductListRow from '../../components/ProductViews/ProductListRow';

function CatalogPage() {
  const classes = useStyles;

  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function load() {
      const categories = await getCatalog();
      //const categories = await axios.get('http://localhost:3334/customer/explore');

      console.log(categories);
      setCategories(categories);

      setLoading(false);
    }
    load();
  }, []);

  return (
    <Container sx={classes.container}>
      <div style={classes.header}>
        <Typography variant="h4" component="h1">
          Catalogo
        </Typography>
      </div>
      {loading && <CircularProgress />}
      {categories.map((c, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={classes.heading}>{c.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              {c.products.map((p, index) => (
                <Grid item key={index} xs={12}>
                  <ProductListRow product={p} />
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
}

export default CatalogPage;
