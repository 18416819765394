import React from 'react';
import Routes from './routes/router';
import 'fontsource-roboto';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// import { CartProvider } from './contexts/Cart';
import { Provider } from 'react-redux';
import { AuthProvider } from './contexts/Auth';
import store from './redux/store';
import theme from './resources/theme';
import { SnackbarProvider } from 'notistack';
export default function App() {
  return (
    <AuthProvider>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <SnackbarProvider>
              <CssBaseline />
              <Routes />
            </SnackbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    </AuthProvider>
  );
}
