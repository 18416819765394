const styles = {
  image: {
    margin: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  media: {
    width: 30,
    height: 30,
  },
  containerDescription: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'stretch',
  },
  name: {
    color: '#000',
    textDecoration: 'none',
    fontSize: 20,
  },
  price: {
    color: theme => theme.palette.primary.main,
    textDecoration: 'none',
    fontSize: 15,
  },
};

export default styles;
