import { makeStyles } from '@mui/material';
import { createTheme } from "@mui/system";
const theme = createTheme();	

export default {
  container: {
    paddingTop: 3,  
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 2,
    marginBottom: 2,
  },

  approval:{
    width:'alto',
    padding:'7.9% ',  
    marginBottom :'1%',
    [theme.breakpoints.down('md')]: {
      textAlign:'center',
     },
  },

  adressClient:{
    padding:'11%',  
    [theme.breakpoints.down('md')]: {
      textAlign:'center',
      marginBottom:'1%',
     },
  },

  orderValue:{
    padding:'11%',  
    marginBottom :'1%',
    [theme.breakpoints.down('md')]: {
      textAlign:'center',
     },
  },

  boxTop:{
    display:'flex',
  },

  boxBotton:{
    display:'flex',
   alignItems:'basile',
  },

  title: {
    marginBottom: 2,
  },

  product: {
    padding: 4,
    [theme.breakpoints.down('md')]: {
     justifyContent:'center',
    },
  },

  spanResume: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
};
