import {
  ADD_PRODUCT,
  DELETE_PRODUCT,
  MINUS_PRODUCT,
  DELETE_ALL_PRODUCT,
  SET_CHECKOUT,
  SET_ADDRESS,
  SET_PAYMENT,
  CLEAR,
} from './actionTypes';

import localStorageName from '../../config/localStorageName';

const storagedProductsCartInit = localStorage.getItem(
  `${localStorageName}:productsCart`,
);

const initialState = {
  productsCart: JSON.parse(storagedProductsCartInit) || [],
  deliveryPrice: 1.99,
  checkout: null,
  address: null,
  payment: null,
};
export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_PRODUCT:
      const newProductsCart = state.productsCart;
      const haveProduct = state.productsCart.indexOf(action.product);
      console.log(haveProduct);
      if (haveProduct > -1)
        newProductsCart[haveProduct].qtd = newProductsCart[haveProduct].qtd + 1;
      else newProductsCart.push(action.product);

      localStorage.setItem(
        `${localStorageName}:productsCart`,
        JSON.stringify(newProductsCart),
      );
      return {
        ...state,
        products: newProductsCart,
      };

    case DELETE_PRODUCT:
      const newProductsCartDELETE_PRODUCT = state.productsCart;
      const haveProductd = state.productsCart.indexOf(action.product);
      console.log(haveProductd);

      if (haveProductd > -1) {
        newProductsCartDELETE_PRODUCT.splice(haveProductd, 1);
      }
      localStorage.setItem(
        `${localStorageName}:productsCart`,
        JSON.stringify(newProductsCartDELETE_PRODUCT),
      );
      return {
        ...state,
        productsCart: newProductsCartDELETE_PRODUCT,
      };

    case MINUS_PRODUCT:
      const newProductsCartMINUS_PRODUCT = state.productsCart;
      const haveProductm = state.productsCart.indexOf(action.product);

      if (haveProductm > -1) {
        newProductsCartMINUS_PRODUCT[haveProductm].qtd =
          newProductsCartMINUS_PRODUCT[haveProductm].qtd - 1;
        if (newProductsCartMINUS_PRODUCT[haveProductm].qtd === 0)
          newProductsCartMINUS_PRODUCT.splice(haveProductm, 1);
      }

      localStorage.setItem(
        `${localStorageName}:productsCart`,
        JSON.stringify(newProductsCartMINUS_PRODUCT),
      );

      return {
        ...state,
        productsCart: newProductsCartMINUS_PRODUCT,
      };

    case DELETE_ALL_PRODUCT:
      localStorage.removeItem(`${localStorageName}:productsCart`);

      return {
        ...state,
        productsCart: [],
      };

    case SET_CHECKOUT:
      return {
        ...state,
        checkout: action.checkout,
      };
    case SET_ADDRESS:
      return {
        ...state,
        address: action.address,
      };
    case SET_PAYMENT:
      return {
        ...state,
        payment: action.payment,
      };
    case CLEAR:
      localStorage.removeItem(`${localStorageName}:productsCart`);
      return {
        ...state,
        productsCart: [],
        checkout: null,
        payment: null,
      };
    default:
      return state;
  }
}
