export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    marginTop: 2,
  },
  title: {
    color: theme => theme.palette.primary.main,
    fontSize: 40,
    marginBottom: 5,
  },
  link: {
    color: '#A6BCD0',
    fontSize: 20,
    fontWeight: 'bold',
    paddingTop: 4,
    '&:hover': {
      color: theme => theme.palette.grey[500],
      transition: '0.5s',
    },
  },
  buttonSumit: {
    marginTop: 5,
  },
  error: {
    color: theme => theme.palette.red[400],
  },
};
