import { makeStyles } from '@mui/material/styles';

import { green } from '@mui/material/colors';

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  boxOne:{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    marginTop:'10%',


  },
  title: {
    color: theme => theme.palette.primary.main,
    fontSize: 40,
    marginBottom: 10,
    display:'flex',
    justifyContent:'center',
  },
  tabs: {
    marginBottom: 2,
  },
  link: {
    color: '#A6BCD0',
    fontSize: 20,
    fontWeight: 'bold',
    paddingTop: 4,
    cursor: 'pointer',

    '&:hover': {
      color: theme => theme.palette.grey[500],
      transition: '0.5s',
    },
  },
  buttonSumit: {
    marginTop: 10,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    marginTop: -3,
    marginLeft: 10,
  },
  error: {
    color: theme => theme.palette.error.main,
  },
};

