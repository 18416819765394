import React, { useContext } from 'react';
// import { Form } from '@unform/web';
// import { Link, useHistory } from 'react-router-dom';
import { Typography, TextField } from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import useStyles from './styles';
import Button from '../../../components/Button';
import Input from '../../../components/form/InputText';

// components material-ui

// context
import AuthContext from '../../../contexts/Auth';

function Login({ setTab }) {
  const classes = useStyles;
  const { errorMessage, loading, register } = useContext(AuthContext);

  const handleSubmit = async data => {
    const { first_name, last_name, email, password } = data;
    if (first_name && last_name && email && password)
      await register(email, password, first_name, last_name);
  };

  return (<div style={classes.boxTwo}>
    <form onSubmit={handleSubmit} sx={classes.form}>
      <Typography variant="h4" sx={classes.title}>
        Criar uma conta
      </Typography>
      {errorMessage && <span style={classes.error}>{errorMessage}</span>}
      <TextField
        placeholder="Primeiro nome"
        type="text"
        name="first_name"
        required
        fullWidth
      />
      <TextField
        placeholder="Segundo nome"
        type="text"
        name="last_name"
        required
        fullWidth
      />
      <TextField
        placeholder="Email"
        type="email"
        name="email"
        required
        fullWidth
      />
      <TextField
        placeholder="Senha"
        type="text"
        name="password"
        required
        fullWidth
      />
      <Button
        type="submit"
        startIcon={<ArrowForwardOutlinedIcon />}
        disabled={loading}
      >
        Criar
      </Button>

      <Typography varinat="body" sx={classes.link} onClick={() => setTab(0)}>
        Login
      </Typography>
    </form>
    </div>
  );
}

export default Login;
