import { makeStyles } from '@mui/material/styles';

export default {
  main: width => ({
    marginTop: width > 800 ? 64 : 0,
    marginBottom: width > 800 ? 0 : 64,
    minHeight: 'calc(100vh - 120px)',
    paddingBottom: 3,
  }),
};
