import React, { useState, useEffect } from 'react';

import { Grid, Typography, Container, Box } from '@mui/material';

import { Link } from 'react-router-dom';

import { getOrders } from '../../services/orderService';
import { formatMoney } from '../../utils/money';
import { formatDateToBr } from '../../utils/date';
import styles from './styles';
function Orders() {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      try {
        const data = await getOrders();

        setData(data.data);
        setLoading(false);
      } catch (err) { }
    }
    getData();
  }, []);
  return (
    <Container sx={{ p: 2 }}>
      <Typography variant="h5">Pedidos </Typography>
      <Grid container>
        {!loading &&
          data.length === 0 &&
          (<Grid item ld={12} xs={12}
            sx={styles.notList}
          >
            <Typography variant="h6">
              Você ainda não possui Pedidos
            </Typography>
          </Grid>)
        }
        {!loading &&
          data.map(order => (
            <Grid item ld={12} xs={12} key={order.id}>
              <Link
                to={`/order/${order.id}`}
                style={{ display: 'flex', padding: 10 }}
              >
                <Grid item sx={{ paddingRight: 2 }}>
                  #{order.organization_order_id}
                </Grid>
                <Grid item sx={{ paddingRight: 2 }}>
                  {order.order_status.name}
                </Grid>
                <Grid item sx={{ paddingRight: 2 }}>
                  {formatDateToBr(order.created_at)}
                </Grid>
                <Grid item sx={styles.money}>
                  {formatMoney(+order.amount)}
                </Grid>
              </Link>
            </Grid>
          ))}
      </Grid>
    </Container>
  );
}

export default Orders;
