import React, { useState, useEffect } from 'react';

import { useHistory, useNavigate } from 'react-router-dom';

// components material-ui
import { Typography, Box, Container } from '@mui/material';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setPayment } from '../../../redux/cart/actions';

// custom coomponents
import PaymentInDeliveryModal from './ModalInDelivery';
// styles
import useStyles from './styles';

const PAYMENT_TITLE = {
  cardCredit: 'Cartão de crédito',
  cardDebit: 'Cartão de débito',
  money: 'Dinheiro',
};

function PaymentMethod() {
  const dispatch = useDispatch();
  const classes = useStyles;

  const [total, setTotal] = useState(0);
  const [open, setOpen] = useState(false);

  const { payment } = useSelector(state => state.cart);
  const cart = useSelector(state => state.cart);
  const deliveryPriceState = useSelector(state => state.cart.deliveryPrice);
  const [deliveryPrice, setDeliveryPrice] = useState(deliveryPriceState);

  useEffect(() => {
    let subTotal = 0;

    cart.productsCart.forEach(product => {
      subTotal += parseFloat(product.price) * product.qtd;
    });

    if (subTotal === 0) setDeliveryPrice(0);
    else setDeliveryPrice(1.99);

    setTotal(subTotal + deliveryPrice);
  }, [deliveryPrice, cart]);
  const [paymentObject, setPaymentObejct] = useState({});
  const [errorPayment, setErrorPayment] = useState(false);
  const setPaymentComponent = payment => {
    if (payment.type === 'money' && !payment.amount) {
      setErrorPayment('insira o valor para troco');
      //return ;
    } else {
      setErrorPayment(false);
    }
    if (payment.type === 'money') {
      if (parseFloat(payment.amount) - total < 0) {
        setErrorPayment('Valor para troco deve ser maior que o total');
        //return ;
      }
      setPaymentObejct({
        ...payment,
        change: parseFloat(payment.amount) - total,
      });
      dispatch(
        setPayment({
          ...payment,
          change: parseFloat(payment.amount) - total,
        }),
      );
    } else {
      setPaymentObejct(payment);
      dispatch(setPayment(payment));
    }

    if (payment.type === 'money' && payment.amount) {
      if (parseFloat(payment.amount) - total >= 0) {
        setErrorPayment(false);
      }
    }
  };

  const navigate = useNavigate();
  return (
    <Container sx={classes.container}>  
      <Typography variant="subtitle1" sx={classes.subTitle}>
        Pagamento 
      </Typography>
      {errorPayment && errorPayment}
      <Box sx={classes.item(false)} onClick={() => setOpen(true)}>
        {!payment && (
          <Typography variant="subtitle1" sx={classes.subTitle}>
            Selecionar
          </Typography>
        )}
        {payment && (
          <Typography variant="subtitle1" sx={classes.subTitle}>
            {PAYMENT_TITLE[payment.type]}
          </Typography>
        )}
      </Box>
      {/* <PaymentInDelivery
        setPayment={setPaymentComponent}
        paymentObject={paymentObject}
        open={open}
        setOpen={setOpen}
      /> */}
      <PaymentInDeliveryModal
        setPayment={setPaymentComponent}
        paymentObject={paymentObject}
        open={open}
        setOpen={setOpen}
      />
    </Container>
    
  );
}

export default PaymentMethod;
