import * as React from 'react';

const SvgComponent = ({ color, ...props }) => (
  <svg
    width="315"
    height="315"
    viewBox="0 0 315 315"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M157.5 315C244.485 315 315 244.485 315 157.5C315 70.5152 244.485 0 157.5 0C70.5152 0 0 70.5152 0 157.5C0 244.485 70.5152 315 157.5 315Z"
      fill="#F2FFF1"
    />
    <path
      d="M46.3882 176.575V186.984"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M51.5931 181.78H41.1841"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M247.805 232.592V243.001"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M253.009 237.796H242.6"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M55.9949 107.265C59.402 107.265 62.1639 104.503 62.1639 101.096C62.1639 97.689 59.402 94.927 55.9949 94.927C52.5879 94.927 49.8259 97.689 49.8259 101.096C49.8259 104.503 52.5879 107.265 55.9949 107.265Z"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M263.679 154.238C267.086 154.238 269.848 151.476 269.848 148.069C269.848 144.662 267.086 141.9 263.679 141.9C260.272 141.9 257.51 144.662 257.51 148.069C257.51 151.476 260.272 154.238 263.679 154.238Z"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M180.567 48.6571C183.974 48.6571 186.736 45.8951 186.736 42.4881C186.736 39.081 183.974 36.3191 180.567 36.3191C177.16 36.3191 174.398 39.081 174.398 42.4881C174.398 45.8951 177.16 48.6571 180.567 48.6571Z"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M130.453 218.019H206.197C209.992 218.059 213.658 216.644 216.442 214.064C219.226 211.485 220.916 207.937 221.165 204.15C221.26 202.179 220.954 200.21 220.266 198.361C219.577 196.511 218.521 194.822 217.16 193.393C215.799 191.965 214.162 190.827 212.348 190.05C210.535 189.273 208.582 188.873 206.609 188.873"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M219.935 197.528C219.935 197.539 219.945 197.549 219.945 197.559C219.945 197.549 219.935 197.539 219.935 197.528L184.412 110.916H93.5809C91.0944 110.917 88.6464 111.529 86.453 112.7C84.2595 113.872 82.3882 115.565 81.0044 117.631C79.6206 119.696 78.7668 122.071 78.5185 124.545C78.2703 127.019 78.6352 129.516 79.5809 131.816L96.5489 173.053C98.4715 177.732 101.741 181.735 105.943 184.552C110.146 187.369 115.09 188.873 120.149 188.874H206.608C209.428 188.873 212.188 189.69 214.554 191.226C216.919 192.762 218.788 194.951 219.935 197.528Z"
      fill="#DFFFCA"
    />
    <path
      d="M219.935 197.528C219.935 197.539 219.945 197.549 219.945 197.559C219.945 197.549 219.935 197.539 219.935 197.528ZM219.935 197.528L184.412 110.916H93.5809C91.0944 110.917 88.6464 111.529 86.453 112.7C84.2595 113.872 82.3882 115.565 81.0044 117.631C79.6206 119.696 78.7668 122.071 78.5185 124.545C78.2703 127.019 78.6352 129.516 79.5809 131.816L96.5489 173.053C98.4715 177.732 101.741 181.735 105.943 184.552C110.146 187.369 115.09 188.873 120.149 188.874H206.608C209.428 188.873 212.188 189.69 214.554 191.226C216.919 192.762 218.788 194.951 219.935 197.528Z"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M132.97 218.019C127.91 218.019 122.965 216.515 118.761 213.699C114.558 210.883 111.286 206.882 109.361 202.203"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M205.822 96.3479C205.822 100.213 204.287 103.92 201.554 106.653C198.821 109.386 195.114 110.921 191.249 110.921H184.41"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M142.202 247.165C150.25 247.165 156.775 240.64 156.775 232.592C156.775 224.543 150.25 218.019 142.202 218.019C134.153 218.019 127.629 224.543 127.629 232.592C127.629 240.64 134.153 247.165 142.202 247.165Z"
      fill="#DFFFCA"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M136.753 231.682C136.753 230.462 137.238 229.292 138.1 228.429C138.963 227.567 140.133 227.082 141.353 227.082"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M101.102 129.297L118.068 170.538"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M124.035 129.297L141.001 170.538"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M146.969 129.297L163.935 170.538"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M169.903 129.297L186.869 170.538"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M109.585 149.918H177.128"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M236.897 87.3099C236.223 85.6724 235.078 84.272 233.607 83.2865C232.136 82.301 230.405 81.7749 228.634 81.7749H220.395C216.53 81.7749 212.823 83.3103 210.09 86.0432C207.357 88.7762 205.822 92.4829 205.822 96.3479H230.842C231.917 96.3477 232.976 96.0827 233.925 95.5764C234.873 95.07 235.682 94.3378 236.281 93.4444C236.879 92.5511 237.249 91.5241 237.356 90.4542C237.464 89.3843 237.306 88.3044 236.897 87.3099V87.3099Z"
      fill="#ACFFB2"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M195.14 247.165C203.188 247.165 209.713 240.64 209.713 232.592C209.713 224.543 203.188 218.019 195.14 218.019C187.091 218.019 180.567 224.543 180.567 232.592C180.567 240.64 187.091 247.165 195.14 247.165Z"
      fill="#DFFFCA"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M189.691 231.682C189.691 230.462 190.176 229.292 191.038 228.429C191.901 227.567 193.071 227.082 194.291 227.082"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M160.135 109.791C160.24 101.273 155.221 91.3911 145.292 92.4841C143.964 92.6328 142.658 92.9393 141.402 93.3971C139.885 93.9551 138.219 93.9551 136.702 93.3971C135.446 92.9393 134.141 92.6328 132.813 92.4841C122.883 91.3841 117.865 101.273 117.97 109.791"
      fill="#DFFFCA"
    />
    <path
      d="M160.135 109.791C160.24 101.273 155.221 91.3911 145.292 92.4841C143.964 92.6328 142.658 92.9393 141.402 93.3971C139.885 93.9551 138.219 93.9551 136.702 93.3971C135.446 92.9393 134.141 92.6328 132.813 92.4841C122.883 91.3841 117.865 101.273 117.97 109.791"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M139.054 92.7789C139.054 91.7372 138.85 90.7055 138.452 89.743C138.053 88.7804 137.469 87.9057 136.732 87.1691C135.996 86.4324 135.121 85.8481 134.159 85.4497C133.196 85.0512 132.165 84.8464 131.123 84.8469"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M150.635 81.198C150.636 82.7191 150.337 84.2255 149.755 85.631C149.173 87.0364 148.32 88.3135 147.245 89.389C146.169 90.4646 144.892 91.3177 143.487 91.8993C142.081 92.481 140.575 92.7799 139.054 92.779C139.053 91.2579 139.352 89.7517 139.934 88.3462C140.515 86.9408 141.368 85.6638 142.444 84.5882C143.52 83.5127 144.796 82.6597 146.202 82.0779C147.607 81.4962 149.114 81.1972 150.635 81.198V81.198Z"
      fill="#DFFFCA"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M132.414 100.4C132.414 100.4 126.495 102.567 125.735 110.317Z"
      fill="#DFFFCA"
    />
    <path
      d="M132.414 100.4C132.414 100.4 126.495 102.567 125.735 110.317"
      stroke="#7BED8D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default SvgComponent;
