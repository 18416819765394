import React, { useState, useEffect } from 'react';

// formik
import { useFormik } from 'formik';
// components
import {
  Grid,
  Box,
  TextField,
  Button,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from '@mui/material';

import styles from './styles';
// services
import { postAddress, putAddress } from '../../../services/accountService';
import { useAlert } from '../../../contexts/Alert';
import { useSnackbar } from 'notistack';

function AddressessList({ activeAddress, filialCepData }) {
  const [filialCep, setFilialCep] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async values => {
    if (!filialCep) {
      enqueueSnackbar('Preencha o bairro', {
        variant: 'error',
      });
    }
    setLoading(true);
    if (activeAddress) {
      await putAddress(activeAddress.id, {
        ...values,
        filial_deliverie_ceps_id: filialCep.id,
        neighborhood: filialCep.name,
        zip_code: filialCep.cep,
      })
        .then(res => {
          enqueueSnackbar('Endereço salvo!', {
            variant: 'success',
          });
        })
        .catch(err => {
          enqueueSnackbar('Erro ao salvar endereço!', {
            variant: 'error',
          });
        });
    } else {
      await postAddress({
        ...values,
        filial_deliverie_ceps_id: filialCep.id,
        neighborhood: filialCep.name,
        zip_code: filialCep.cep,
      })
        .then(res => {
          enqueueSnackbar('Endereço salvo!', {
            variant: 'success',
          });
        })
        .catch(err => {
          enqueueSnackbar('Erro ao salvar endereço!', {
            variant: 'error',
          });
        });
    }
    setLoading(false);
  };
  const formik = useFormik({
    initialValues: {
      first_name: activeAddress?.first_name || '',
      last_name: activeAddress?.last_name || '',
      neighborhood: activeAddress?.neighborhood || '',
      number: activeAddress?.number || '',
      phone_number: activeAddress?.phone_number || '',
      street: activeAddress?.street || '',
      complement: activeAddress?.complement || '',
    },
    onSubmit: values => {
      handleSubmit(values);
    },
  });
  return (
    <>
      <Box component="form" onSubmit={formik.handleSubmit} sx={styles.form}>
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                placeholder="Primeiro nome"
                name="first_name"
                required
                onChange={formik.handleChange}
                value={formik.values.first_name}
                // sx={classes.input}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                placeholder="Sobrenome"
                name="last_name"
                required
                onChange={formik.handleChange}
                value={formik.values.last_name}
                // sx={classes.input}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder="Telefone"
                name="phone_number"
                required
                onChange={formik.handleChange}
                value={formik.values.phone_number}
                // sx={classes.input}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder="Logadouro"
                name="street"
                required
                onChange={formik.handleChange}
                value={formik.values.street}
                // sx={classes.input}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder="Número"
                name="number"
                required
                onChange={formik.handleChange}
                value={formik.values.number}
                // sx={classes.input}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="neighborhood-select-label">Bairro</InputLabel>
                <Select
                  labelId="neighborhood-select-label"
                  id="neighborhood-select"
                  value={filialCep}
                  label="Bairro"
                  onChange={event => {
                    formik.setFieldValue(
                      'neighborhood',
                      event.target.value.name,
                    );
                    setFilialCep(event.target.value);
                    console.log(event.target.value);
                  }}
                >
                  {filialCepData.map((cep, index) => (
                    <MenuItem value={cep} key={index}>
                      {cep.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 5 }}>
          <Button
            type="submit"
            // sx={classes.buttonSumit}
            loading={loading}
            variant="contained"
            fullWidth
          >
            Salvar
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default AddressessList;
