export default {
  container: {
    width: '100%',
    backgroundColor: theme => theme.palette.grey[900],
    display: 'flex',
    justifyContent: 'center',
    alignitems: 'center',
    color: theme => theme.palette.grey[100],
    padding: 2,
  },
};
