import React from 'react';

// import { Form } from '@unform/web';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import IconButton from '@mui/material/IconButton';
import Input from '../../components/form/InputText';

const useStyles = {
  input: {
    border: 'none',
    borderRadius: 0,
    backgroundColor: '#fff',
    minWidth: 300,
    maxWidth: '100%',
    minHeight: 50,
    fontSize: 25,
    color: theme => theme.palette.grey[300],
    padding: 10,
  },
  form: {
    display: 'flex',
  },
  submit: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
};
function InputSearch(props) {
  const classes = useStyles;
  return (
    <form
      onSubmit={data => {
        console.log('pesquisa buttom');
        props.setSearch(data.search);
      }}
      sx={classes.form}
    >
      <h4>{props.category}</h4>
      <Input
        type="text"
        name="search"
        placeholder="Pesquisar por produtos"
        sx={classes.input}
      />
      <div style={classes.submit}>
        <IconButton
          color="primary"
          aria-label="Pesquisar"
          component="button"
          type="submit"
          size="large"
        >
          <SearchRoundedIcon />
        </IconButton>
      </div>
    </form>
  );
}

export default InputSearch;
