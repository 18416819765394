import React, { useState, useEffect } from 'react';

// components material-ui
import { Typography, Container, CircularProgress, Box } from '@mui/material';

// api services
// import { Link } from 'react-router-dom';
import { getCatalog } from '../../services/exploreService';
import baseUrl from '../../services/baseUrl';
import ButtonCart from '../../components/ButtonCart';
import config from '../../config/config';
// router
// import axios from 'axios';

import useStyles from './styles';
import ProductCartGrid from '../../components/ProductViews/ProductCartGrid';
import useWindowSize from '../../contexts/WindowSize';
import { useAuth } from '../../contexts/Auth';

function CatalogPage() {
  const classes = useStyles;
  const window = useWindowSize();

  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const { filialInfo } = useAuth();

  useEffect(() => {
    async function load() {
      const categories = await getCatalog();
      //const categories = await axios.get('http://localhost:3334/customer/explore');

      console.log(categories);
      setCategories(categories);

      setLoading(false);
    }
    load();
  }, []);

  return (
    <Container sx={classes.container}>
      {window.width <= 800 && (
        <Box sx={classes.header}>
          <Box sx={classes.headerTitle}>
            <Typography variant="h6" component="h1">
              {filialInfo.fantasy_name}
            </Typography>
          </Box>
          <Box>
            <ButtonCart />
          </Box>
        </Box>
      )}

      <Box sx={classes.companyInfoContainer}>
        <Box sx={classes.logo}>
          {filialInfo?.logo && (
            <img
              style={classes.logo}
              src={`${baseUrl}${filialInfo.logo}`}
              alt={filialInfo.fantasy_name}
            />
          )}
        </Box>
        <Typography variant="h" component="h">
          {filialInfo?.slogan && filialInfo.slogan}
        </Typography>
        <Typography variant="body2" component="p">
          Périodo de atendimento: Seg - Sex - De{' '}
          {filialInfo?.timeDelivery?.init[0]}:{filialInfo?.timeDelivery?.init[1]}{' '}
          às {filialInfo?.timeDelivery?.end[0]}:{filialInfo?.timeDelivery?.end[1]}
        </Typography>
      </Box>
      {loading && <CircularProgress />}
      <Box sx={classes.list}>
        {categories.map((c, index) => (
          <>
            <Typography sx={classes.heading}>{c.name}</Typography>
            <Box sx={classes.listItem}>
              {c.products.map((p, index) => (
                <Box item key={index}>
                  <ProductCartGrid
                    product={{
                      ...p,
                      qtd: 1,
                    }}
                  />
                </Box>
              ))}
            </Box>
          </>
        ))}
      </Box>
    </Container>
  );
}

export default CatalogPage;
