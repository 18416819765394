import axios from 'axios';
import baseUrl from './baseUrl';
import api from './api';
import config from '../config/config';

export async function signIn(email, password) {
  const response = await axios.post(`${baseUrl}/customer/login`, {
    email,
    password,
  });

  return response.data;
}

export async function register(email, password, first_name, last_name, filial_id, organization_id) {
  const response = await api.post(`${baseUrl}/customer/register`, {
    username: Date.now(),
    email,
    password,
    first_name,
    last_name,
  }, {
    headers: {
      filial_id,
      organization_id
    }
  });

  return response.data;
}

export async function getUser(id) {
  console.log(api);
  const user = await api.get(`/customer/account/account`);
  console.log(user);
  const profile = await api.get(`/customer/account/profile`);
  const address = await api.get(`/customer/account/address`);
  console.log(address);

  return {
    user: user.data.user,
    address: address.data,
    profile: profile.data.profile,
  };
}
export async function getFilialInfo() {
  const hostname = window.location.hostname
  const subdomain = hostname.split('.')[0]

  const filial_id = subdomain === 'localhost' ? config.filial_id : subdomain
  const res = await api.get(`/customer/mobile/home`, {
    headers: {
      filial_id
    }
  });

  return res.data;
}
