// import { fade, } from '@mui/material/styles';

import { createTheme } from "@mui/system";
const theme = createTheme();	
export default {
  root: {
    flexGrow: 1,
    // position: 'fixed',
    // top: 0,
    left: 0,
    width: '100vw',
    zIndex: 99,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  AppBarTop: {
    backgroundColor: theme => theme.palette.grey[200],
    boxShadow: 'none',
    position: 'fixed',
  },
  AppBarBottom: {
    backgroundColor: theme => theme.palette.grey[200],
    boxShadow: 'none',
    position: 'fixed',
    top: 'auto',
    bottom: 0,
  },
  left: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: theme => {
      return theme.breakpoints.up('sm') ? 'flex-start' : 'flex-end';
    },
    display: 'flex',
  },
  center: {
    flexGrow: 1,
    display: theme => {
      return theme.breakpoints.up('sm') ? 'block' : 'none';
    },
    justifyContent: 'center',
    alignItems: 'center',
  },
  right: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: theme => {
      return theme.breakpoints.up('sm') ? 'flex-end' : 'flex-start';
    },
  },
  icon: {
    stroke: theme => theme.palette.grey[800],
    '&:hover': {
      stroke: theme => theme.palette.primary.main,
    },
  },
  menuItemLeft: {
    // marginRight: 5,
    // padding: 5,
    stroke: theme => theme.palette.grey[800],
    display: 'inline-flex',
    '& svg:hover g *': {
      color: theme => theme.palette.primary.main,
      stroke: theme => theme.palette.primary.main,
    },
  },
  menuItemLeftActive: {
    stroke: theme => theme.palette.grey[800],
    display: 'inline-flex',
    '& g *': {
      color: theme => theme.palette.primary.main,
      stroke: theme => theme.palette.primary.main,
    },
  },
  menuItemRight: {
    stroke: theme => theme.palette.grey[800],
    display: 'inline-flex',
    '& svg:hover g *': {
      color: theme => theme.palette.primary.main,
      stroke: theme => theme.palette.primary.main,
    },
  },
  menuItemRightActive: {
    stroke: theme => theme.palette.grey[800],
    display: 'inline-flex',
    '& svg:hover g *': {
      color: theme => theme.palette.primary.main,
      stroke: theme => theme.palette.primary.main,
    },
  },
    title: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      display: theme => {
        return theme.breakpoints.up('sm') ? 'flex' : 'flex' ;
      },

     [theme.breakpoints.down('sm')]: {
     marginLeft:'70px',
     alignItems:'center',
    },
    },
  search: {
    position: 'relative',
    borderRadius: theme => theme.shape.borderRadius,
    // backgroundColor: fade(theme => theme.palette.common.white, 0.15),
    '&:hover': {
      // backgroundColor: fade(theme => theme.palette.common.white, 0.25),
    },
    // marginLeft: 0,
    width: '50%',
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: 1,
    //   width: 'auto',
    // },
  },
  searchIcon: {
    padding: theme => theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme => theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${4})`,
    transition: theme => theme.transitions.create('width'),
    width: '100%',
    //   [theme.breakpoints.up('sm')]: {
    //     width: '12ch',
    //     '&:focus': {
    //       width: '20ch',
    //     },
    //   },
  },
};
