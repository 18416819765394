import React, { useState, useEffect } from 'react';
// components @material-ui
import {
  Typography,
  Grid,
  Button,
  Dialog,
  ListItemText,
  ListItem,
  List,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Box,
} from '@mui/material';
import useStyles from './styles';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import * as cartActions from '../../../redux/cart/actions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ListAddress({
  listAddress,
  open,
  setOpen,
  setAddressEdit,
  setIsEdit,
}) {
  const classes = useStyles;
  const dispatch = useDispatch();
  const { address } = useSelector(state => state?.cart);

  const handleSelectAddress = address => {
    setOpen(false);
    dispatch(cartActions.setAddress(address));
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div >
      <Dialog
      
        fullWidth
        maxWidth={800}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
       
      >
        <Box sx={classes.modalBottomContent}>
          <List
          sx={classes.containerbox}>
            {listAddress &&
              listAddress.map((item, index) => (
                <Box
                  sx={classes.item}
                  onClick={() => handleSelectAddress(item)}
                >
                  <Grid container key={index} sx={classes.item}>
                    <Grid item xs={8}>
                      <Typography>
                        {item.street}, {item.number}, {item.neighborhood},{' '}
                        {item.zip_code}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        onClick={() => {
                          console.log(item);
                          setIsEdit(true);
                          setAddressEdit(item);
                        }}
                        sx={classes.textClickAction}
                      >
                        Editar
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            <Divider />
          </List>{' '}
        </Box>
      </Dialog>
    </div>
  );
}

export default ListAddress;
