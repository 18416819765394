import React, { useState, useEffect } from 'react';

// components material-ui
import { Typography, Container, Grid, CircularProgress } from '@mui/material';

// api services
import { Link } from 'react-router-dom';
import { getCategories } from '../../services/exploreService';

import useStyles from './styles';

function Categories() {
  const classes = useStyles;

  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function load() {
      const categories = await getCategories();
      //const categories = await axios.get('http://localhost:3334/customer/explore');

      console.log(categories);
      setCategories(categories);

      setLoading(false);
    }
    load();
  }, []);

  return (
    <Container sx={classes.container}>
      <div style={classes.header}>
        <Typography variant="h4" component="h1">
          Categorias
        </Typography>
      </div>
      {loading && <CircularProgress />}

      {!loading &&
        categories.map((category, index) => (
          <div key={index}>
            <Grid container sx={classes.list}>
              <Grid item xs={12} lg={12}>
                <Link to={`/search?category=${category.id}`}>
                  <Typography
                    variant="h6"
                    component="h3"
                    sx={classes.categoryName}
                  >
                    {category.name}
                    <span> Ver tudo</span>
                  </Typography>
                </Link>
              </Grid>

              {category.subCategoriesActive.map((subCategory, index) => (
                <Grid item key={index}>
                  <Link
                    sx={classes.listItem}
                    to={`/search?category=${subCategory.id}`}
                  >
                    <div style={classes.icon} />
                    <div style={classes.title}>
                      <Typography variant="subtitle2">
                        {subCategory.name}
                      </Typography>
                    </div>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </div>
        ))}
    </Container>
  );
}

export default Categories;
