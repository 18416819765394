import { createTheme } from '@mui/system';
const theme = createTheme();

export default {
  container: {
    // paddingTop: 4,
    padding: 2,
    // [theme.breakpoints.up('sm')]: {
    //   boxShadow: '4px 9px 14px 6px #9f9696',
    //   padding: '5%',
    //   marginTop: '7%',
    // },
  },
  headerContainer: {
    paddingBottom: 1,
    padding: 2,
  },
  input: {
    marginTop: 20,
  },
  list: {
    // backgroundColor: theme => theme.palette.grey[300],
    borderRadius: 1,
    padding: 2,

    justifyContent: 'space-between',
  },
  listItem: {
    justifyContent: 'space-between',
    display: 'flex',
    paddingBottom: 3,
  },
  listItemEdit: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: theme => theme.palette.primary.main,
    },
  },
  ButtonGoOut: {
    background: '#F0F4F8',
    color: 'error.main',
  },
};
