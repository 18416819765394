import React from 'react';
import clsx from 'clsx';
import LoadingButton from '@mui/lab/LoadingButton';
import styles from './styles.js';

function Button(props) {
  const { children, loading, disabled, variant, className, ...other } = props;
  const classes = styles;
  return (
    <>
      <LoadingButton
        className={clsx(classes.buttonPrimary, className)}
        disabled={disabled || loading}
        variant={variant || 'contained'}
        loading={loading}
        fullWidth
        {...other}
      >
        {children || 'No Text'}
      </LoadingButton>
    </>
  );
}

export default Button;
