import Colors from '../../../resources/Colors';

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',

  },
  title: {
    color: Colors.primary.main,
    fontSize: 40,
  },
  link: {
    color: '#A6BCD0',
    fontSize: 20,
    paddingTop: 10,
    fontWeight: 'bold',
    cursor: 'pointer',
    display:'flex',
    justifyContent:'center',
    '&:hover': {
      color: theme => theme.palette.grey[500],
      transition: '0.5s',
    },
  },
  error: {
    color: theme => theme.palette.error.main,
  },
};
