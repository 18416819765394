import React, { useState } from 'react';

// formik
import { useFormik } from 'formik';
// components
import { Grid, Box, TextField, Button } from '@mui/material';

import styles from './styles';
// services
import { putMyAccount } from '../../../services/accountService';
import { useSnackbar } from 'notistack';

function AccountForm({ account }) {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async values => {
    setLoading(true);
    await putMyAccount(account.id, {
      ...values,
    })
      .then(res => {
        enqueueSnackbar('Conta atualizada!', {
          variant: 'success',
        });
      })
      .catch(err => {
        enqueueSnackbar('Erro ao atualizar conta!', {
          variant: 'error',
        });
      });

    setLoading(false);
  };
  const formik = useFormik({
    initialValues: {
      name: account?.name || '',
      lastName: account?.lastName || '',
      phone: account?.phone || '',
    },
    onSubmit: values => {
      handleSubmit(values);
    },
  });
  return (
    <>
      <Box component="form" onSubmit={formik.handleSubmit} sx={styles.form}>
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                placeholder="Nome"
                name="name"
                required
                onChange={formik.handleChange}
                value={formik.values.name}
                defaultValue={formik.values.name}
                // sx={classes.input}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder="Sobrenome"
                name="lastName"
                required
                onChange={formik.handleChange}
                value={formik.values.lastName}
                // sx={classes.input}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder="Telefone"
                name="phone"
                required
                onChange={formik.handleChange}
                value={formik.values.phone}
                // sx={classes.input}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder="Email"
                name="email"
                disabled
                required
                // onChange={formik.handleChange}
                value={account?.email}
                // sx={classes.input}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 5 }}>
          <Button
            type="submit"
            // sx={classes.buttonSumit}
            loading={loading}
            variant="contained"
            fullWidth
          >
            Salvar
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default AccountForm;
