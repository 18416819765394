import Colors from '../../../resources/Colors';

export default {
  container: {
    width: `100%`,
    marginTop: 15,
    marginBottom: 15,
    display: 'flex',
    alignitems: 'center',
  },
  // input: input(theme),
  inputIcon: {
    // ...input(theme),
    paddingLeft: 50,
  },
  icon: {
    margin: 1,
    position: 'absolute',
    color: Colors.input.text,
  },
  error: {
    color: 'red',
  },
};
