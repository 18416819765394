import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import theme from '../../resources/theme';

const useStyles = {
  root: {
    display: 'flex',
  },
  formControl: {
    margin: 2,
  },
  Cprice: {
    display: 'flex',
    flexDirection: 'row',
  },
  price: {
    marginLeft: 2,
    color: theme => theme.palette.primary.main,
  },
};

class CheckboxesGroup extends React.Component {
  //const classes = useStyles;

  state = {
    optionals: [],
    addtionals: [],
  };
  componentDidMount() {
    let optionals = this.props.food.optionals;
    optionals.map((item, i) => {
      optionals[i].checked = true;
    });
    let addtionals = this.props.food.addtionals;
    addtionals.map((item, i) => {
      addtionals[i].checked = false;
    });
    //return optionals
    this.setState({
      optionals,
      addtionals,
    });
  }

  handleChange = event => {
    let optionals = this.state.optionals;
    optionals[parseInt(event.target.name)].checked = event.target.checked;
    console.log(optionals[parseInt(event.target.name)]);
    this.setState({ optionals });
    this.props.setRemoveOptional(optionals);
  };
  handleChangeAdd = event => {
    let addtionals = this.state.addtionals;
    addtionals[parseInt(event.target.name)].checked = event.target.checked;
    this.setState({ addtionals });

    this.props.setPriceAddtional(addtionals);
  };

  render() {
    const classes = useStyles;
    return (
      <div style={classes.root}>
        <FormControl component="fieldset" sx={classes.formControl}>
          <FormGroup>
            <Typography variant="h6" component="h6">
              Opcionais
            </Typography>
            {this.state.optionals.map((item, i) => (
              <FormControlLabel
                key={i}
                control={
                  <Checkbox
                    checked={item.checked}
                    onChange={this.handleChange}
                    name={i}
                    color="primary"
                  />
                }
                label={item.name}
              />
            ))}

            <Typography variant="h6" component="h6">
              Adicionais
            </Typography>
            {this.state.addtionals.map((item, i) => (
              <>
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox
                      checked={item.checked}
                      onChange={this.handleChangeAdd}
                      name={i}
                      color="primary"
                    />
                  }
                  label={
                    <span style={classes.Cprice}>
                      <Typography content="body">{item.name}</Typography>{' '}
                      <FormHelperText sx={classes.price}>
                        {Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(Number(item.price))}
                      </FormHelperText>
                    </span>
                  }
                />
              </>
            ))}
          </FormGroup>
        </FormControl>
      </div>
    );
  }
}
CheckboxesGroup.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default CheckboxesGroup;
