
import { createTheme } from "@mui/system";
const theme = createTheme();
export default {
  container: {
    width: 'auto',
    maxWidth: 212,
    borderRadius: 8,
    background: '#f0f4f8',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 2,
    marginLeft: 4,
    paddngTop:2,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '40px',
    },


  },
  trash: {
    color: theme => theme.palette.grey[400],
    fontSize: 23,
    alignSelf: 'flex-end',
    margin: 1,
    cursor: 'pointer',
    '&:hover': {
      color: theme => theme.palette.error.main,
    },
  },
  media: {
    position: 'relative',
    width: 'auto',
    maxWidth: 212,
    height: '100%',
    objectFit: 'cover',
    // borderRadius: '32px 32px 0px 0px',
    zIndex: 8,
  },
  image: {
    backgroundColor: 'transparent',
    height: 160,
    width: 212,
    justifyContent: 'center',
    borderRadius: '32px 32px 0px 0px',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 10,
    alignItems: 'center',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  productName: {
    fontSize: 16,
    color: theme => theme.palette.grey[700],
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 1,
    textTransform: "uppercase"    

  },
  productList: {
    fontSize: 16,
    color: theme => theme.palette.grey[700],
    paddingTop: 1,
    marginRight: 2,
    justifyContent: 'center',
  },
  productPrice: {
    fontSize: 15,
    color: theme => theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 1,
  },
  buttonCart: {
    backgroundColor: theme => theme.palette.primary.main,
    borderRadius: 8,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
  },
};
