import { green } from '@mui/material/colors';
import Colors from '../../resources/Colors';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  title: {
    color: Colors.primary.main,
    fontSize: 40,
  },
  link: {
    color: '#A6BCD0',
    fontSize: 20,
    paddingTop: 20,
    fontWeight: 'bold',
    '&:hover': {
      color: theme => theme.palette.grey[500],
      transition: '0.5s',
    },
  },
  error: {
    color: theme => theme.palette.error.main,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    marginTop: -35,
    marginLeft: 152,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    marginTop: 2
  },
  button: {
    marginTop: 2
  }
};
export default styles;
