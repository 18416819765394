import React, { useState, useEffect } from 'react';

import { Grid, Typography, Container, Box, Button } from '@mui/material';

import { Link } from 'react-router-dom';

import { getAddress } from '../../../services/accountService';
import { formatMoney } from '../../../utils/money';
import IconEdit from '../../../resources/icons/IconEdit';
import styles from './styles';
function AddressessList() {
  const [data, setData] = useState([]);
  const [activeAddress, setActiveAddress] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      try {
        const data = await getAddress();

        setData(data.address);
        setLoading(false);
      } catch (err) {}
    }
    getData();
  }, []);
  return (
    <Container sx={styles.root}>
      <Box>
        <Box sx={styles.headerContainer}>
          <Typography variant="h5">Endereços</Typography>
        </Box>

        {!loading &&
          data.map(address => (
            <Box
              component={Link}
              to={`/account/address/${address.id}`}
              sx={styles.listItem}
              key={address.id}
            >
              <Grid container sx={styles.listItem}>
                <Grid item xs={3}>
                  <Typography variant="body1" color="GrayText">
                    {address.first_name} {address.last_name}
                  </Typography>
                </Grid>
                <Grid item sx={styles.edit} xs={6}>
                  <Typography variant="body1" color="GrayText">
                    {address.street}, {address?.number}, {address?.neighborhood}
                  </Typography>
                </Grid>
                <Grid item sx={styles.edit} xs={3}>
                  <IconEdit />
                </Grid>
              </Grid>
            </Box>
          ))}
      </Box>
      <Box>
        <Button
          sx={{ mt: 2 }}
          fullWidth
          variant="contained"
          component={Link}
          to="/account/address/new"
        >
          Novo endereço
        </Button>
      </Box>
    </Container>
  );
}

export default AddressessList;
