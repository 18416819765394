import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import * as auth from '../services/authService';
import api from '../services/api';

import localStorageName from '../config/localStorageName';

const AuthContext = createContext();
export const AuthProvider = ({ value, children }) => {
  const [user, setUser] = useState(null);
  const [filialInfo, setFilialInfo] = useState(null);
  const [apiLoading, setApiLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  // iniciando state
  useEffect(
    useCallback(() => {
      async function loadStorageData() {
        const storagedUser = await localStorage.getItem(
          `${localStorageName}:user`,
        );
        const storagedToken = await localStorage.getItem(
          `${localStorageName}:token`,
        );
        if (storagedUser === 'undefined') return null;

        const filialInfo = await auth.getFilialInfo().then(data => {
          setFilialInfo(data);
          return data
        });
        if (storagedUser && storagedToken) {
          // console.log(storagedUser);
          api.defaults.headers.Authorization = `${JSON.parse(storagedToken).Authorization
            }`;

        }
        if (filialInfo) {
          api.defaults.headers.filial_id = filialInfo.filial_id;
          api.defaults.headers.organization_id = filialInfo.organization.id;
        }
        setApiLoading(true);
      }

      loadStorageData();
    }, []),
    [value],
  );

  function setAuth(response) {
    setUser(response);
    api.defaults.headers.Authorization = `${response.token.Authorization}`;

    localStorage.setItem(`${localStorageName}:user`, JSON.stringify(response));
    localStorage.setItem(
      `${localStorageName}:token`,
      JSON.stringify(response.token),
    );
  }
  function setUserAddress(address) {
    setUser({
      ...user,
      activeAddress: address,
    });
  }

  /**
   * @description login
   * @param {*} email
   * @param {*} password
   */
  async function signIn(email, password) {
    setLoading(true);
    setErrorMessage(null);
    try {
      const response = await auth.signIn(email, password);
      console.log(response);
      setAuth(response);
    } catch (err) {
      setErrorMessage(err.message);
    }
    setLoading(false);
  }

  /**
   * @description create user
   * @param {String} email
   * @param {String} password
   * @param {String} first_name
   * @param {String} last_name
   */
  async function register(email, password, first_name, last_name) {
    setLoading(true);
    setErrorMessage(null);
    try {
      const response = await auth.register(
        email,
        password,
        first_name,
        last_name,
        filialInfo.filial_id,
        filialInfo.organization.id
      );
      console.log(response);
      setAuth(response);
    } catch (err) {
      if (err?.response?.data?.message)
        setErrorMessage(err.response.data.message);
      else
        setErrorMessage(err.message);
    }
    setLoading(false);
  }
  /**
   * @description logout
   */
  async function signOut() {
    setUser(null);
    await localStorage.clear();
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        filialInfo,
        signIn,
        register,
        signOut,
        loading,
        apiLoading,
        errorMessage,
        setUserAddress,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.');
  }

  return context;
}

export const Api = api;

export default AuthContext;
