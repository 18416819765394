import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Pages from '../pages';
import HomeCatalog from '../pages/HomeCatalog';

import Template from '../components/Template';

import AuthContext from '../contexts/Auth';

function RoutesComponent() {
  const { apiLoading } = useContext(AuthContext);
  return (
    <Router>
      {apiLoading && (
        <Routes>
          <Route path="/" element={<Template />}>
            <Route index element={<HomeCatalog />} />
            <Route path="/search" element={<Pages.Search />} />
            <Route exact path="/product/:id" element={<Pages.Product />} />

            <Route exact path="/cart" element={<Pages.Cart />} />

            <Route exact path="/checkout" element={<Pages.Checkout />} />

            <Route exact path="/account" element={<Pages.Account />} />
            <Route exact path="/account/edit" element={<Pages.AccountEdit />} />
            <Route
              exact
              path="/account/address"
              element={<Pages.AddressList />}
            />
            <Route
              exact
              path="/account/address/new"
              element={<Pages.AddressEdit />}
            />
            <Route
              exact
              path="/account/address/:id"
              element={<Pages.AddressEdit />}
            />

            <Route exact path="/order/:id" element={<Pages.Order />} />

            <Route exact path="/orders" element={<Pages.OrderList />} />

            <Route exact path="/category" element={<Pages.Category />} />

            <Route exact path="/catalog" element={<Pages.Catalog />} />

            <Route path="/login" element={<Pages.Login />} />

            <Route path="/register" element={<Pages.Register />} />

            {/* <Route exact path="/404" element={<Pages.NotFoundPage />} /> */}
          </Route>
          {/* <Redirect from="*" to="/404" /> */}
        </Routes>
      )}
      {!apiLoading && 'Carregando'}
    </Router>
  );
}

export default RoutesComponent;
