export const ADD_PRODUCT = 'ADD_PRODUCT';

export const DELETE_PRODUCT = 'DELETE_PRODUCT';

export const MINUS_PRODUCT = 'MINUS_PRODUCT';
export const DELETE_ALL_PRODUCT = 'DELETE_ALL_PRODUCT';

export const SET_CHECKOUT = 'SET_CHECKOUT';

export const SET_ADDRESS = 'SET_ADDRESS';

export const SET_PAYMENT = 'SET_PAYMENT';

export const CLEAR = 'CLEAR';
