import { createTheme, padding } from "@mui/system";
const theme = createTheme();

export default {
  container: {
   

  },
  subTitle: {
    marginTop: '6px',
    marginBottom: 2,
    
    [theme.breakpoints.down('sm')]: {
    display:'flex',
    justifyContent:'center',
    
    padding:'2%',  
    marginTop:'2%',  
  },
  // [theme.breakpoints.down('xs')]: {
  //   display:'flex',
  //   justifyContent:'center',
    
  //   padding:'2%',  
  //   marginTop:'2%',  
  // },


  },
  item: checked => ({
    marginBottom: 2,
    minWidth: 150,
    maxWidth: '100%',
    borderRadius: 1,
    backgroundColor: '#f0f4f8',

    display: 'flex',
    border: theme =>
      `1px solid ${checked ? theme.palette.primary.main : 'none'}`,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 1,
    cursor: 'pointer',
  }),
  textClickAction: {
    cursor: 'pointer',
    color: theme => theme.palette.grey[500],
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    mt: 3,
    mb: 3,
  },
  modalBottomContent: {
    position: 'fixed',
    width: '100%',
    bottom: 0,
    left: 0,
    minHeight: 500,
    maxWidth: 800,
    bgcolor: 'background.paper',
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
    pt: 6,
  },
};
