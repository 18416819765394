import React from 'react';

import { Link } from 'react-router-dom';

function NotFoundPage() {
  // const history = useHistory();
  return (
    <div>
      <h1>Opps, O que está procurando não esta por aqui</h1>
      <div onClick={() => {}}> Voltar</div>
      <Link to="/"> Página inicial</Link>
    </div>
  );
}

export default NotFoundPage;
