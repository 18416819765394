import { createTheme } from "@mui/system";
const theme = createTheme();

export default {
  container: {
    marginBottom: '5%',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  containerbox: {
    width: '100%',
    marginTop: '10%',
    marginBottom: '10%',
  },
  inputOutlinedInput: {
    width: '100%',
  },

  subTitle: {
    marginTop: 1,
    marginBottom: 2,
  },
  titleModal: {
    wisth: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    mb: 2
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  modalContent: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
    padding: '5%',
  },
  modalBottomContent: {
    display: 'flex',
    width: '100%',
    // transform: 'translate(25%, 0%)',
    bgcolor: 'background.paper',
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
    pt: 6,
  },
  item: {
    marginBottom: 2,
    minWidth: '150px',
    maxWidth: '100%',
    maxHeight: '153px',
    borderRadius: 1,
    backgroundColor: '#f0f4f8',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 1,

  },
  textClickAction: {
    cursor: 'pointer',
    color: theme => theme.palette.grey[500],
    display: 'flex',
    justifyContent: 'center',
  },
};
