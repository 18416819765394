import { createTheme } from '@mui/system';
const theme = createTheme();

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc( 98vh - 58px)',
    alingItens: 'space-between',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      minHeight: '500px',
      backgrounColor: ' #fff',
      boxShadow:
        '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%)',
      padding: '5%',
    },
  },
  subTitle: {
    marginTop: 5,
    marginBottom: 5,
  },
};
