import React, { useContext } from 'react';
// import { Form } from '@unform/web';

// components material-ui
import { Typography, TextField } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// icons
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

// custom components
import useStyles from './styles';
// import Input from '../../../components/form/InputText';
import Button from '../../../components/Button';
import Register from '../Register';

// context
import AuthContext from '../../../contexts/Auth';

function Login({ redirect }) {
  const classes = useStyles;

  const [tab, setTab] = React.useState(0);
  const { signIn, errorMessage, loading } = useContext(AuthContext);

  const handleSubmit = async data => {
    if (data.email && data.password) await signIn(data.email, data.password);
  };
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div style={classes.container}>
      <div style={classes.boxOne}>
        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
          sx={classes.tabs}
        >
          <Tab label="Login" />
          <Tab label="Cadastro" />
        </Tabs>
        {tab === 0 && (
          <form onSubmit={handleSubmit} sx={classes.form}>
            <Typography variant="h4" sx={classes.title}>
              Login
            </Typography>
            {errorMessage && <span style={classes.error}>{errorMessage}</span>}
            <TextField
              placeholder="Email"
              name="email"
              type="email"
              StartIcon={() => <EmailOutlinedIcon />}
              required
              fullWidth
            />
            <TextField
              placeholder="Senha"
              type="password"
              name="password"
              StartIcon={() => <LockOutlinedIcon />}
              required
              fullWidth
            />
            <Button
              type="submit"
              sx={classes.buttonSumit}
              startIcon={<ArrowForwardOutlinedIcon />}
              disabled={loading}
            >
              Logar
            </Button>
          </form>
        )}
        {tab === 1 && <Register setTab={setTab} />}
      </div>
    </div>
  );
}

export default Login;
