import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

export default function IconStore({ color }) {
  return (
    <SvgIcon>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 1H4.24799C3.82145 1 3.39913 1.08403 3.00507 1.24725C2.611 1.41048 2.2529 1.64969 1.95129 1.95129C1.64969 2.2529 1.41048 2.611 1.24725 3.00507C1.08403 3.39913 1 3.82145 1 4.24799V17.748C1 18.1745 1.08403 18.5969 1.24725 18.991C1.41048 19.385 1.64969 19.7431 1.95129 20.0447C2.2529 20.3463 2.611 20.5856 3.00507 20.7488C3.39913 20.912 3.82145 20.996 4.24799 20.996H17.748C18.6094 20.996 19.4356 20.6538 20.0447 20.0447C20.6538 19.4356 20.996 18.6094 20.996 17.748V10"
          stroke="#748A9D"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.464 5.60016L9.06403 17.0002H5V12.9332L16.4 1.5332"
          stroke="#748A9D"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.0957 13.3252L8.6407 14.8702"
          stroke="#748A9D"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
