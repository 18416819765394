import * as React from 'react';

const SvgComponent = ({ color, ...props }) => (
  <svg
    width={27}
    height={28}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.17 26.749a2.269 2.269 0 1 0 0-4.538 2.269 2.269 0 0 0 0 4.538Z"
      stroke={color || '#748A9D'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.1 22.211h11.792a2.312 2.312 0 0 0 2.33-2.16 2.27 2.27 0 0 0-2.266-2.378H7.496a3.976 3.976 0 0 1-3.675-2.462L1.18 8.791a2.356 2.356 0 0 1 2.179-3.254h15.206"
      stroke={color || '#748A9D'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.492 22.211a3.975 3.975 0 0 1-3.676-2.463v0M18.565 5.537a2.268 2.268 0 0 0 2.269-2.269M9.972 26.749a2.269 2.269 0 1 0 0-4.538 2.269 2.269 0 0 0 0 4.538ZM4.53 8.398l2.64 6.42M8.1 8.398l2.641 6.42M11.67 8.398l2.642 6.42M15.24 8.398l2.642 6.42M17.5 5.537l5.556 13.505M5.851 11.608h10.515M25.672 1.861A1.392 1.392 0 0 0 24.385 1h-1.282a2.268 2.268 0 0 0-2.269 2.268h3.9a1.019 1.019 0 0 0 .943-1.407h-.005Z"
      stroke={color || '#748A9D'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
