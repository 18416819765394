import React, { useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

// components material-ui
import { Typography, TextField, Box } from '@mui/material';

// icons
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

// custom components
import useStyles from './styles';
import Button from '../../components/Button';

// context
import AuthContext from '../../contexts/Auth';

function Login() {
  const classes = useStyles;
  let [searchParams] = useSearchParams();
  const { user, signIn, errorMessage, loading } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (searchParams.get('redirect')) {
        navigate(`/${searchParams.get('redirect')}`);
      } else navigate('/');
    }
  }, [user]);

  const handleSubmit = async data => {
    if (data.email && data.password) await signIn(data.email, data.password);
  };
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: values => {
      handleSubmit(values);
    },
  });
  return (
    <div style={classes.container}>
      <Typography variant="h4" component="h1" sx={classes.title}>
        LOGIN
      </Typography>

      <Box component="form" onSubmit={formik.handleSubmit} sx={classes.form}>
        {errorMessage && (
          <Typography variant="body2" style={classes.error}>
            Email/senha incorretos
          </Typography>
        )}
        <TextField
          placeholder="Email"
          name="email"
          type="email"
          StartIcon={() => <EmailOutlinedIcon />}
          required
          onChange={formik.handleChange}
          value={formik.values.email}
          sx={classes.input}
          fullWidth
        />
        <TextField
          placeholder="Senha"
          type="password"
          name="password"
          StartIcon={() => <LockOutlinedIcon />}
          required
          onChange={formik.handleChange}
          value={formik.values.password}
          fullWidth
          sx={classes.input}
        />
        <Button
          type="submit"
          sx={classes.buttonSumit}
          startIcon={<ArrowForwardOutlinedIcon />}
          loading={loading}
        >
          Logar
        </Button>
      </Box>
      <Link to="/register">
        <Typography varinat="body" sx={classes.link}>
          Criar conta
        </Typography>
      </Link>
      <Link to="/">
        <Typography varinat="body" sx={classes.link}>
          Mais tarde
        </Typography>
      </Link>
    </div>
  );
}

export default Login;
