import React from 'react';

import { Paper, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import styles from './syles';

function ProductListGrid({ organizationName, product, ...props }) {
  const classes = styles;
  console.log(product);
  return (
    <Paper elevation={1} sx={classes.container}>
      <Typography sx={classes.enterpriseName} />
      <div style={classes.media} />
      <Typography sx={classes.productName}>
        {product && product?.name}
      </Typography>
      <Typography sx={classes.productPrice}>
        {product?.price &&
          Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(Number(product.price))}
      </Typography>
      <Link to={`/product/${product?.id}`}>
        <Button
          sx={classes.buttonCart}
          fullWidth
          startIcon={<ShoppingBasketOutlinedIcon />}
        >
          Visualizar
        </Button>
      </Link>
    </Paper>
  );
}

export default ProductListGrid;
