import axios from 'axios';
import config from '../config/config';
import baseUrl from './baseUrl'
console.log(config);
const hostname = window.location.hostname
const subdomain = hostname.split('.')[0]

const filial_id = subdomain === 'localhost' ? config.filial_id : subdomain

const api = axios.create({
  // baseURL: `http://localhost:3334`,
  baseURL: baseUrl,
  headers: {
    // filial_id: filial_id,
    // organization_id: filial_id,
  },
});

export default api;
