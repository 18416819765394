import React, { useState, useEffect } from 'react';

import { Container, Grid, Paper, Typography } from '@mui/material';

import moment from 'moment';

import { getOrder } from '../../services/orderService';
import { getProductMedia } from '../../services/exploreService';

import { useParams } from 'react-router-dom';

import { Link } from 'react-router-dom';

import useStyles from './styles';

function Order() {
  const classes = useStyles;
  const { id } = useParams();

  const [order, setOrder] = useState(null);

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    async function getData() {
      try {
        const order = await getOrder(id);
        console.log(order);
        setOrder(order);
        setLoading(false);
      } catch (err) {
        setErrorMessage(err.message);
        setLoading(false);
      }
    }
    getData();
  }, [id]);

  const getStatus = status => {
    if (status.delivered) {
      return 'Entregue';
    }
    if (status.sent) {
      return 'Saiu para entrega';
    }
    if (status.separation) {
      return 'Em preparo para envio';
    }
    if (status.approved) {
      return 'Aprovado';
    }
    return 'Esperando aprovação';
  };

  return (
    <Container sx={classes.container}>
      {!loading && !errorMessage && (
        <div >
        <div sx={classes.boxTop}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} >
              <Paper sx={classes.approval}>
                <Typography variant="subTitle1" sx={classes.title}>
                  {order?.order_status && getStatus(order.order_status)}
                </Typography>
                <Typography variant="body1">
                  {moment(order.created_at).format('DD/MM/YYYY')}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper sx={classes.orderValue}>
                <Typography variant="subTitle1" sx={classes.title}>
                  {order.amount.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Typography>
                <Typography variant="body1">{order.payment.name}</Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper sx={classes.adressClient}>
                <Typography variant="subTitle1">
                  {order.address_delivery.street},
                  {order.address_delivery.number},
                  {order.address_delivery.neighborhood},
                  {order.address_delivery.complement}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          </div>


          
          <Grid sx={classes.boxBotton} container spacing={2}>
            <Grid item xs={12} md={8}>
              <Paper sx={classes.paper}>
                <Typography variant="subTitle1" sx={classes.title}>
                  Produtos
                </Typography>
                {order?.saleItems?.map(product => (
                  <>
                    <Grid container spacing={2} sx={classes.product}>
                      <Grid item md={2}>
                        <Link to={`/product/${product?.product?.id}`}>
                          <img
                            style={{
                              width: 50,
                            }}
                            src={getProductMedia(product.media.uri)}
                            alt=""
                          />
                        </Link>
                      </Grid>
                      <Grid item md={4}>
                        <Link to={`/product/${product.id}`}>
                          {product?.product?.name}
                        </Link>
                      </Grid>
                      <Grid item md={3}>
                        {product.quantity}
                      </Grid>
                      <Grid item md={3}>
                        {(product.price * product.quantity).toLocaleString(
                          'pt-br',
                          {
                            style: 'currency',
                            currency: 'BRL',
                          },
                        )}
                      </Grid>
                    </Grid>
                  </>
                ))}
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper sx={classes.paper}>
                <Typography variant="subTitle1" sx={classes.title}>
                  {' '}
                  Resumo do Pedido
                </Typography>
                <Typography variant="body2" sx={classes.spanResume}>
                  <span>subtotal</span>{' '}
                  <span>
                    {' '}
                    {order.amount_items.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </span>{' '}
                </Typography>
                <Typography variant="body2" sx={classes.spanResume}>
                  <span>Frete</span>{' '}
                  <span>
                    {order.amount_shipping.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </span>{' '}
                </Typography>
                <Typography variant="body2" sx={classes.spanResume}>
                  <span>Pagamento</span> <span>{order.payment.type}</span>{' '}
                </Typography>
                <Typography variant="body1" sx={classes.spanResume}>
                  <span>Total</span>{' '}
                  <span>
                    {order.amount.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </span>{' '}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
      </div>
      )}
      {errorMessage && errorMessage}
    </Container>
  );
}

export default Order;
