import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link, useLocation, matchRoutes } from 'react-router-dom';
import Container from '@mui/material/Container';
import Badge from '@mui/material/Badge';
// import InputBase from '@mui/material/InputBase';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { useSelector } from 'react-redux';
// import { useCart } from '../../../contexts/Cart';
import useStyles from './styles';
import config from '../../../config/config';

import IconStore from '../../../resources/icons/IconStore';
import IconRecipes from '../../../resources/icons/IconRecipes';
import IconCart from '../../../resources/icons/IconCart';
import IconAccount from '../../../resources/icons/IconAccount';
//import SearchIcon from '../../../resources/icons/IconSearch';
import useWindowSize from '../../../contexts/WindowSize';
import { useAuth } from '../../../contexts/Auth';
const useStylesBootstrap = {
  arrow: {
    color: theme => theme.palette.primary.main,
    zIndex: 99999,
  },
  tooltip: {
    backgroundColor: theme => theme.palette.primary.main,
    borderRadius: 20,
    zIndex: 99999,
    '& + MuiTooltip-popper': {
      zIndex: 99999,
    },
  },
};
function BootstrapTooltip(props) {
  const classes = useStylesBootstrap;

  return <Tooltip arrow classes={classes} {...props} />;
}

const routes = [
  { path: '/' },
  { path: '/account' },
  { path: '/cart' },
  { path: '/catalog' },
];

export default function SearchAppBar() {
  const { productsCart } = useSelector(state => state.cart);
  const { filialInfo, filialId } = useAuth();
  const classes = useStyles;
  const location = useLocation();
  const matchRoute = matchRoutes(routes, location);
  const [{ route }] = matchRoute || [{}];

  // useEffect(() => {
  //   console.log(location);
  //   setLocationPathname(location.pathname);
  // }, [location]);
  useEffect(() => {
    console.log(location);
  }, [location]);
  const window = useWindowSize();
  return (
    <Box sx={classes.root}>
      <AppBar
        position="fixed"
        sx={window.width > 800 ? classes.AppBarTop : classes.AppBarBottom}
      >
        <Toolbar>
          <Container maxWidth="lg" sx={classes.container}>
            <Box sx={classes.left}>
              <BootstrapTooltip TransitionComponent={Zoom} title="Início">
                <Button
                  component={Link}
                  to="/"
                  sx={
                    route?.path === '/'
                      ? classes.menuItemLeftActive
                      : classes.menuItemLeft
                  }
                >
                  <IconStore sx={classes.icon} />
                </Button>
              </BootstrapTooltip>
              <BootstrapTooltip title="Catálogo" TransitionComponent={Zoom}>
                <Button
                  component={Link}
                  to={config.toSales}
                  sx={
                    route?.path === '/catalog'
                      ? classes.menuItemLeftActive
                      : classes.menuItemLeft
                  }
                >
                  <IconRecipes sx={classes.icon} />
                </Button>
              </BootstrapTooltip>
            </Box>
            <Box sx={classes.center}>
              <Typography sx={classes.title} variant="h6" noWrap>
                {window.width > 800 && filialInfo.fantasy_name}
                {filialId}
              </Typography>
            </Box>
            <Box sx={classes.right}>
              <BootstrapTooltip title="Sacola" TransitionComponent={Zoom}>
                <Button
                  component={Link}
                  to="/cart"
                  sx={
                    route?.path === '/cart'
                      ? classes.menuItemRightActive
                      : classes.menuItemRight
                  }
                >
                  <Badge badgeContent={productsCart.length} color="primary">
                    <IconCart
                      color={route?.path === '/cart' ? '#7BED8D' : false}
                    />
                  </Badge>
                </Button>
              </BootstrapTooltip>
              <BootstrapTooltip title="Conta" TransitionComponent={Zoom}>
                <Button
                  component={Link}
                  to="/account"
                  sx={
                    route?.path === '/account'
                      ? classes.menuItemRightActive
                      : classes.menuItemRight
                  }
                >
                  <IconAccount
                    sx={classes.icon}
                    color={route?.path === '/account' ? '#7BED8D' : false}
                  />
                </Button>
              </BootstrapTooltip>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
