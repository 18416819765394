import React, { useState, useEffect } from 'react';

// contexts
import { Container, Typography, Grid, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import config from '../../config/config';
import IconCartGreen from '../../resources/icons/IconCartGreen';
// router
// components material-ui

// custom make styles
import useStyles from './styles';

// custom components
import ProductCartList from '../../components/ProductViews/ProductCartList';
import Footer from './footer';

export default function CartPage() {
  const classes = useStyles;

  const [productsCartState, setProductsCart] = useState([]);

  const { productsCart } = useSelector(state => state.cart);

  useEffect(() => {
    console.log('atualiza', productsCart);
    setProductsCart(productsCart);
  }, [productsCart]);
  return (
    <Container maxWidth="md" sx={classes.container}>
      <Container maxWidth="md" sx={classes.headerRoot}>
        <Typography variant="subtitle1" component="h1">
          Sacola de Compras
        </Typography>
      </Container>
      {/* <Box> */}
      <Grid
        container
        sx={classes.productListContainer}
        overflow="auto"
        justifyContent="center"
        alignItems="center"
      >
        {productsCartState.map((item, index) => (
          <Grid
            item
            key={index}
            sx={classes.productListItem}
            md={1}
          >
            <ProductCartList product={item} />
          </Grid>
        ))}
        {productsCartState.length === 0 && (
          <Box sx={classes.productListEmpty}>
            <Typography variant="h4" component="h2">
              Sacola de vazia
            </Typography>
            <IconCartGreen />
            <Link to={config.toSales}>Ir as compras</Link>
          </Box>
        )}
      </Grid>
      {/* </Box> */}
      < Footer />
    </Container>
  );
}
