import Colors from '../../../resources/Colors';

export default {
  container: {
    backgroundColor: Colors.grey.ligther,
    padding: 2,
    borderRadius: 1,
  },
  subTitle: {
    marginTop: 1,
    marginBottom: 1,
  },
  totalContainer: {
    marginRight: 2,
    marginBottom: 2,
    justifySelf: 'center',
  },
  totalValues: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  submit: {
    marginRight: 2,
  },
};
