import React, { useState, useEffect } from 'react';

// components
import { Typography, Container, Box, CircularProgress } from '@mui/material';

import styles from './styles';
// services
import { getMyAccount } from '../../../services/accountService';

// custom components
import Form from './Form';
import { useStore } from 'react-redux';

function AccountPage() {
  const [account, setAccount] = useState(null);

  const { user } = useStore(state => state.auth);

  const [initalLoading, setInitialLoading] = useState(true);
  useEffect(() => {
    async function getData() {
      try {
        const account = await getMyAccount();
        console.log('account', account);
        setAccount(account);
        setInitialLoading(false);
      } catch (err) {
        setInitialLoading(false);
      }
    }
    getData();
  }, []);
  return (
    <Container sx={{ p: 2 }}>
      <Box sx={styles.headerContainer}>
        <Typography variant="h5">Minha conta</Typography>
      </Box>

      {initalLoading && <CircularProgress />}
      {!initalLoading && account && (
        <>
          <Form account={account} />
        </>
      )}
    </Container>
  );
}

export default AccountPage;
