import React, { useState, useEffect } from 'react';
// components @material-ui
import {
  Typography,
  Grid,
  Button,
  Dialog,
  TextField,
  Checkbox,
  Slide,
  Box,
} from '@mui/material';
import useStyles from './styles';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import * as cartActions from '../../../redux/cart/actions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaymentInDelivery({
  open,
  setOpen,
  setPayment,
  paymentObject,
  ...props
}) {
  const classes = useStyles;
  const dispatch = useDispatch();
  const { payment } = useSelector(state => state?.cart);
  const [amountMoney, setAmountMoney] = useState('');
  const handleSave = () => {
    if (payment.type === 'money') {
      console.log(payment);
      dispatch(
        setPayment({
          ...payment,
          change: amountMoney,
        }),
      );
    }

    setOpen(false);
  };
  const setMoney = value => {
    setAmountMoney(value);
  };
  return (
    <Dialog
      fullWidth
      maxWidth={800}
      open={open}
      // onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Box sx={classes.modalBottomContent}>
        <Grid container sx={classes.container}>
          <Grid
            item
            xs={12}
            sx={classes.item(paymentObject.type === 'cardCredit')}
            onClick={() => {
              setPayment({
                type: 'cardCredit',
              });
            }}
          >
            <Typography variant="body1">Cartão de crédito</Typography>
          </Grid>
        </Grid>
        <Grid container sx={classes.container}>
          <Grid
            item
            xs={12}
            sx={classes.item(paymentObject.type === 'cardDebit')}
            onClick={() => {
              setPayment({
                type: 'cardDebit',
              });
            }}
          >
            <Typography variant="body1">Cartão de debito</Typography>
          </Grid>
        </Grid>
        <Grid container sx={classes.container}>
          <Grid
            item
            xs={12}
            sx={classes.item(paymentObject.type === 'money')}
            onClick={() => {
              setPayment({
                type: 'money',
              });
            }}
          >
            <Typography variant="body1">Dinheiro</Typography>
          </Grid>
        </Grid>

        {paymentObject.type === 'money' && (
          <Grid container>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Troco para"
                variant="outlined"
                type="number"
                value={amountMoney}
                onChange={e => setMoney(e.target.value)}
                fullWidth={true}
              />
              <Typography>{}</Typography>
            </Grid>
          </Grid>
        )}
        <Button
          variant="contained"
          fullWidth
          sx={classes.button}
          onClick={handleSave}
        >
          Salvar
        </Button>
      </Box>
    </Dialog>
  );
}

export default PaymentInDelivery;
