import * as Yup from 'yup';
const yupString = Yup.string().min(1, 'Muito curto').max(50, 'Muito Longo');

const AddressSchema = Yup.object().shape({
  first_name: yupString.required('Obrigatório'),
  last_name: yupString.required('Obrigatório'),
  phone_number: yupString,
  complement: yupString,
  neighborhood: yupString,
  number: yupString,
  street: yupString,
  zip_code: yupString,
});

export default AddressSchema;
