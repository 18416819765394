import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Typography } from '@mui/material';
import Button from '../Button';
import { Link } from 'react-router-dom';
import config from '../../config/config';

function Item(props) {
  return (
    <div
      style={{
        backgroundImage: `url(${props.item.backgroundImage})`,
        minHeight: 'calc( 100vh - 154px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#000',
      }}
    >
      <Typography variant="h5">{props.item.name}</Typography>
      <Typography
        variant="h1"
        component="h1"
        style={{ textAlign: 'center', marginBottom: 20 }}
      >
        {props.item.description}
      </Typography>
      <Link to={props.item.url}>
        <Button style={{ justifySelf: 'center' }}>Cardápio</Button>
      </Link>
    </div>
  );
}
export default function Example(props) {
  const items = [
    {
      name: config.siteTitle,
      description: config.slogan,
      backgroundImage: '',
      url: config.toSales,
      buttonAction: 'Produto',
    },
  ];

  return (
    <Carousel
      next={(next, active) =>
        console.log(`we left ${active}, and are now at ${next}`)
      }
      prev={(prev, active) =>
        console.log(`we left ${active}, and are now at ${prev}`)
      }
      animation="slide"
    >
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}
